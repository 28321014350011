import {AppState, IVoConfig} from '../interfaces/Interfaces'
import CaasApi from './CaasApi'
import {getPublicSettings} from '../publicConfig'
import VoConfig, {tags} from '../vo/VoConfig'

const {getCurrentLanguageOrFallBackByPath} = require('common/Languages')

import {
  isArticleActiveNow,
  searchNavigationNodeByUrl,
  resolveAllGraphFlowReferences,
  replaceMediaDomainRecursive,
  createNodes,
} from './CaasHelperTools'

function CaasHelperException(message) {
  this.message = message
  this.name = 'CaasHelperException'
}

CaasHelperException.prototype.toString = function () {
  return this.name + ': ' + this.message
}

export default class CaasHelper {
  voConfig: IVoConfig
  caasApi: any

  constructor() {
    this.voConfig = new VoConfig()
    this.caasApi = null

    this.fetchAll = this.fetchAll.bind(this)
    this.injectConfig = this.injectConfig.bind(this)
    this.fetchDomain = this.fetchDomain.bind(this)
    this.fetchWebsiteSettingsId = this.fetchWebsiteSettingsId.bind(this)
    this.fetchWebsiteSettings = this.fetchWebsiteSettings.bind(this)
    this.fetchModelTypes = this.fetchModelTypes.bind(this)
    this.parseCategories = this.parseCategories.bind(this)
    this.parseResultByTypeId = this.parseResultByTypeId.bind(this)
    this.injectAppStateAndFetchContent = this.injectAppStateAndFetchContent.bind(this)
    this.fetchOverlay = this.fetchOverlay.bind(this)
    this.fetchContentByNavigationNode = this.fetchContentByNavigationNode.bind(this)
  }

  async fetchAll(path: string, host: string) {
    this.voConfig.publicConfig = await getPublicSettings()
    const {caasEndpoint, caasDb, user, pwd} = this.voConfig.publicConfig
    this.caasApi = new CaasApi(caasEndpoint, caasDb, user, pwd)

    const currentLanguage = getCurrentLanguageOrFallBackByPath(path)
    this.voConfig.modelDefinitions = await this.fetchModelTypes()
    this.voConfig.contentType = await this.caasApi.fetchTags()
    this.voConfig.domain = await this.fetchDomain(host)
    const websiteId = await this.fetchWebsiteSettingsId()
    if (!websiteId) {
      return {
        config: this.voConfig,
        navigationTree: null,
        content: null,
        modalView: null,
      }
    }
    this.voConfig.websiteSettings = await this.fetchWebsiteSettings(websiteId)

    const props = {
      config: this.voConfig,
      navigationTree: this.voConfig.websiteSettings.rootNavigationNode,
      content: null,
      modalView: null,
    }

    // Fetch Content
    let foundNode = searchNavigationNodeByUrl(props.navigationTree, path, currentLanguage)
    if (foundNode) {
      props.content = await this.fetchContentByNavigationNode(
        props.navigationTree,
        foundNode,
        currentLanguage
      )
    }

    return props
  }

  async injectAppStateAndFetchContent(path: string, cache) {
    const appState: AppState = cache
    appState.config = Object.assign(new VoConfig(), cache.config)
    this.voConfig = appState.config
    this.voConfig.publicConfig = await getPublicSettings()
    const {caasEndpoint, caasDb, user, pwd} = this.voConfig.publicConfig
    this.caasApi = new CaasApi(caasEndpoint, caasDb, user, pwd)

    // Fetch Content
    const currentLanguage = getCurrentLanguageOrFallBackByPath(path)
    const foundNode = searchNavigationNodeByUrl(appState.navigationTree, path, currentLanguage)
    if (foundNode) {
      appState.content = await this.fetchContentByNavigationNode(
        appState.navigationTree,
        foundNode,
        currentLanguage
      )
    }
    return appState
  }

  injectConfig(config: IVoConfig) {
    this.voConfig = config
    const {caasEndpoint, caasDb, user, pwd} = this.voConfig.publicConfig
    this.caasApi = new CaasApi(caasEndpoint, caasDb, user, pwd)
  }

  /**
   * @param host
   */
  async fetchDomain(host: string) {
    let currentDomain = null
    if (typeof window !== 'undefined') {
      // for local development only
      currentDomain = window.location.host
    } else {
      currentDomain = host
    }

    let result = await this.caasApi.query({
      all: {
        tag: tags.domain,
      },
    })

    const domainSetting = result.find((item) => {
      return item.domains.indexOf(currentDomain) > -1
    })

    if (!domainSetting) {
      throw new CaasHelperException(`domain ${currentDomain} is not specified`)
    }
    return domainSetting
  }

  /**
   * fetches the website settings
   */
  async fetchWebsiteSettingsId() {
    let result = await this.caasApi.query({
      mapList: {
        value: {
          all: {
            tag: tags.website,
          },
        },
        expression: {
          metarialize: {
            id: {},
          },
        },
      },
    })

    const domainObject = result.find((item) => {
      return item.id === this.voConfig.domain.serve
    })

    if (domainObject) {
      return domainObject.id
    }
    return null
  }

  async fetchWebsiteSettings(_websiteId: string) {
    if (!_websiteId) {
      return null
    }
    const query = {
      graphFlow: {
        start: {
          newRef: {
            id: _websiteId,
            model: {
              tag: tags.website,
            },
          },
        },
        flow: [
          {
            from: {
              tag: tags.website,
            },
            forward: [
              {
                tag: tags.media,
              },
              {
                tag: tags.translations,
              },
              {
                tag: tags.footer,
              },
              {
                tag: tags.navigationNode,
              },
            ],
          },
          {
            from: {
              tag: tags.navigationNode,
            },
            forward: [
              {
                tag: tags.navigationNode,
              },
            ],
            backward: [
              {
                tag: tags.page,
              },
            ],
          },
          {
            from: {
              tag: tags.footer,
            },
            forward: [
              {
                tag: tags.media,
              },
            ],
          },
        ],
      },
    }

    let result = await this.caasApi.query(query)
    const {getContentIdByTag} = this.voConfig
    replaceMediaDomainRecursive(this.voConfig, result)
    const websitePool = result[getContentIdByTag(tags.website)]
    const websiteId = Object.keys(websitePool)[0]
    let website = websitePool[websiteId]
    const navigationNodes = this.parseCategories(result, website.rootNavigationNode)
    website = resolveAllGraphFlowReferences(
      this.voConfig,
      navigationNodes,
      result,
      website,
      getContentIdByTag(tags.website)
    )
    website.rootNavigationNode = navigationNodes
    return website
  }

  async fetchModelTypes() {
    let result = await this.caasApi.query({
      do: {
        metaMeta: {
          metarialize: {
            id: {},
          },
        },
        return: {
          mapList: {
            value: {
              all: {
                tag: '_model',
              },
            },
            expression: {
              bind: 'metaMeta',
            },
          },
        },
      },
    })

    return result.reduce((prevItem, item) => {
      prevItem[item.id] = item.value
      return prevItem
    }, {})
  }

  parseCategories(result, rootNavigationNodeId) {
    const {getContentIdByTag} = this.voConfig
    const nodePool = result[getContentIdByTag(tags.navigationNode)]
    const articlePool = result[getContentIdByTag(tags.page)]
    return createNodes(nodePool, articlePool, [rootNavigationNodeId], 'root')[0] || []
  }

  /**
   *
   * @param navigationTree
   * @param overlayId id of the desired overlay object
   */
  async fetchOverlay(navigationTree, overlayId) {
    const query = {
      graphFlow: {
        start: {
          newRef: {
            id: overlayId,
            model: {
              tag: tags.overlay,
            },
          },
        },
        flow: [
          {
            from: {
              tag: tags.overlay,
            },
            forward: [
              {
                tag: tags.blockSnippet,
              },
              {
                tag: tags.blockVimeo,
              },
              {
                tag: tags.blockTextSlideShow,
              },
              {
                tag: tags.overlayTwoImagesText,
              },
            ],
          },
          {
            from: {
              tag: tags.blockTextSlideShow,
            },
            forward: [
              {
                tag: tags.media,
              },
            ],
          },
          {
            from: {
              tag: tags.overlayTwoImagesText,
            },
            forward: [
              {
                tag: tags.media,
              },
            ],
          },
        ],
      },
    }

    const {getContentIdByTag} = this.voConfig
    const result = await this.caasApi.query(query)
    return this.parseResultByTypeId(navigationTree, result, getContentIdByTag(tags.overlay))
  }

  /**
   * fetch content and return a the result
   * @param navigationTree
   * @param navigationNode
   * @param currentLanguage
   */
  async fetchContentByNavigationNode(
    navigationTree,
    navigationNode: {content: any; id: any},
    currentLanguage
  ) {
    if (!(navigationNode && navigationNode.content && navigationNode.content.content)) {
      console.info('fetchContentByNavigationNode: no content object provided in navigationNode')
      return null
    }

    let query: any
    if (navigationNode.content.content.hasOwnProperty('templateBlockListPalavrion')) {
      query = {
        graphFlow: {
          start: {
            newRef: {
              id: navigationNode.id,
              model: {
                tag: tags.navigationNode,
              },
            },
          },
          flow: [
            {
              from: {
                tag: tags.navigationNode,
              },
              backward: [
                {
                  tag: tags.page,
                },
              ],
            },
            {
              from: {
                tag: tags.page,
              },
              forward: [
                {
                  tag: tags.templateBlockListPalavrion,
                },
              ],
            },
            {
              from: {
                tag: tags.templateBlockListPalavrion,
              },
              forward: [
                {
                  tag: tags.blockCorporateHome,
                },
                {
                  tag: tags.blockSlider,
                },
                {
                  tag: tags.blockRichText,
                },
                {
                  tag: tags.blockTitle,
                },
                {
                  tag: tags.blockTeaser,
                },
                {
                  tag: tags.blockFollow,
                },
                {
                  tag: tags.blockTeaserSpot,
                },
                {
                  tag: tags.blockLocations,
                },
                {
                  tag: tags.blockTestimonials,
                },
                {
                  tag: tags.blockTwoImagesText,
                },
                {
                  tag: tags.blockSpacer,
                },
                {
                  tag: tags.blockVimeo,
                },
                {
                  tag: tags.blockGridThreeColumns,
                },
                {
                  tag: tags.blockInstagramLightWidget,
                },
                {
                  tag: tags.media,
                },
              ],
            },
            {
              from: {
                tag: tags.blockCorporateHome,
              },
              forward: [
                {
                  tag: tags.media,
                },
              ],
            },
            {
              from: {
                tag: tags.blockSlider,
              },
              forward: [
                {
                  tag: tags.media,
                },
              ],
            },
            {
              from: {
                tag: tags.blockTeaser,
              },
              forward: [
                {
                  tag: tags.media,
                },
              ],
            },
            {
              from: {
                tag: tags.blockTeaserSpot,
              },
              forward: [
                {
                  tag: tags.media,
                },
              ],
            },
            {
              from: {
                tag: tags.blockTwoImagesText,
              },
              forward: [
                {
                  tag: tags.media,
                },
              ],
            },
            {
              from: {
                tag: tags.blockTestimonials,
              },
              forward: [
                {
                  tag: tags.media,
                },
              ],
            },
            {
              from: {
                tag: tags.blockGridThreeColumns,
              },
              forward: [
                {
                  tag: tags.media,
                },
                {
                  tag: tags.widgetBookatable,
                },
                {
                  tag: tags.widgetTripadvisor,
                },
              ],
            },
          ],
        },
      }
    } else {
      console.info('no template found')
      return null
    }

    const {getContentIdByTag} = this.voConfig
    const result = await this.caasApi.query(query)
    let parsedResult = {
      article: this.parseResultByTypeId(navigationTree, result, getContentIdByTag(tags.page)),
      navigationNodeId: navigationNode.id,
    }

    if (isArticleActiveNow(parsedResult.article, currentLanguage)) {
      return parsedResult
    }
    return null
  }

  /**
   *
   * @param navigationTree
   * @param result
   * @param typeId
   * @returns {*}
   */
  parseResultByTypeId(navigationTree, result, typeId: string) {
    if (!(navigationTree && result && typeId && result.hasOwnProperty(typeId))) {
      return null
    }
    replaceMediaDomainRecursive(this.voConfig, result)
    const objectPool = result[typeId]
    const firstObjectId = Object.keys(objectPool)[0]
    let rootObject = objectPool[firstObjectId]
    rootObject = resolveAllGraphFlowReferences(
      this.voConfig,
      navigationTree,
      result,
      rootObject,
      typeId
    )
    rootObject['_id'] = firstObjectId
    rootObject['_type'] = typeId
    return rootObject
  }

  /**
   *
   * @param navigationTree
   * @param result
   * @param typeId
   * @returns {*}
   */
  parseResultListByTypeId(navigationTree, result, typeId: string) {
    if (!(navigationTree && result && typeId && result.hasOwnProperty(typeId))) {
      return null
    }
    replaceMediaDomainRecursive(this.voConfig, result)
    const objectPool = result[typeId]

    const resultList = []
    for (let [objectId, rootObject] of Object.entries(objectPool)) {
      rootObject = resolveAllGraphFlowReferences(
        this.voConfig,
        navigationTree,
        result,
        rootObject,
        typeId
      )
      rootObject['_id'] = objectId
      rootObject['_type'] = typeId
      resultList.push(rootObject)
    }
    return resultList
  }
}
