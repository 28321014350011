const supportedLanguages = ['de', 'en']

export const newsLetterFormLinks = {
  de: ' https://newsletter.palavrion.com/',
  en: ' https://newsletter.palavrion.com/',
}
export const newsLetterStrings = {
  de: 'Newsletter abonnieren',
  en: 'get our news and offers',
}

/**
 * Get language code by path
 * @param path
 */
export function getCurrentLanguageByPath(path) {
  const regex = new RegExp(`^\/?(${supportedLanguages.join('|')})`, 'i')
  const match = regex.exec(path)
  if (!(match && match.length > 0)) {
    return 'unknown'
  }
  return match[1]
}

/**
 * Fall Back Language is en
 * @param path
 */
export function getCurrentLanguageOrFallBackByPath(path) {
  let currentLanguage = getCurrentLanguageByPath(path)
  return currentLanguage === 'unknown' ? 'en' : currentLanguage
}

module.exports.newsLetterFormLinks = newsLetterFormLinks
module.exports.newsLetterStrings = newsLetterStrings
module.exports.supportedLanguages = supportedLanguages
module.exports.getCurrentLanguageByPath = getCurrentLanguageByPath
module.exports.getCurrentLanguageOrFallBackByPath = getCurrentLanguageOrFallBackByPath
