import * as React from 'react'
import ModRichText from '../common/ModRichText'
import ModImgTag from '../common/ModImgTag'
import ModAnchorTag from '../common/ModAnchorTag'
import {FormattedMessage} from 'react-intl'

declare var Cookiebot: any
export interface ModBlockTeaserProps {
  content: {
    list: any
  }
  currentLanguage: string
  pathname: string
  pageColor: string
}

export default class ModBlockTeaser extends React.Component<ModBlockTeaserProps, {}> {
  element: HTMLDivElement[]

  componentDidMount() {
    // @ts-ignore
    Cookiebot.runScripts()
  }

  render() {
    const {content, currentLanguage, pathname, pageColor} = this.props

    this.element = []
    const teaserList = content.list.map((item, index) => {
      if (!item.isDeactivated) {
        if (!item._i18nCL) {
          return null
        }
        const i18n = item._i18nCL

        let vimeo = null
        let image = null

        if (i18n.vimeoId) {
          vimeo = (
            <div className="aspectRatio">
              <div className="cookieconsent-optout-marketing">
                <div className="cookieconsent-optout-marketing-custom">
                  <h3>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        Cookiebot.renew()
                      }}
                    >
                      <FormattedMessage
                        id="cookiePolicy.acceptMarketing"
                        defaultMessage="Please accept marketing cookies to see this content."
                      />
                    </a>
                  </h3>
                </div>
              </div>
              <iframe
                data-cookieconsent="marketing"
                data-cookieblock-src={'https://player.vimeo.com/video/' + i18n.vimeoId}
              />
            </div>
          )
        } else if (item.image) {
          image = (
            <ModAnchorTag linkObject={i18n.imageLink} pathname={pathname}>
              <ModImgTag imgObject={item.image} width={1600} height={1024} />
            </ModAnchorTag>
          )
        }

        return (
          <div className="block-teaser-wrapper" key={index}>
            <div className="block-teaser-container-image">
              {vimeo}
              {image}
            </div>

            <div className="block-teaser-container-text">
              <div className="content">
                <h3 className="typo-h3">{i18n.title}</h3>
                <ModRichText richText={i18n.text} pathname={pathname} />
              </div>
            </div>
          </div>
        )
      }
    })

    return <div className={'block-teaser bg_' + pageColor}>{teaserList}</div>
  }
}
