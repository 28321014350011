import * as React from 'react'
import * as svg4everybody from 'svg4everybody'
import {FormattedMessage} from 'react-intl'
import ModSvg from '../../components/common/ModSvg'

interface ModBlockFollowProps {
  content: {
    twitterUserId: string
    instagram: string
    facebook: string
  }
  pageColor: string
}

export default class ModBlockFollow extends React.Component<ModBlockFollowProps> {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      svg4everybody({
        polyfill: true,
      })
    }
  }

  render() {
    const {content, pageColor} = this.props

    let twitter
    let instagram
    let facebook
    let count = null

    if (content.twitterUserId !== '') {
      count += 1

      twitter = (
        <a
          className="follow-item-small twitter"
          href={'https://twitter.com/intent/follow?user_id=' + content.twitterUserId}
          target="_blank"
        >
          <li className="followIcon">
            <ModSvg url={require('static/img/svg/icon_twitter.svg')} id="icon" />
          </li>
        </a>
      )
    }

    if (content.instagram !== '') {
      count += 1

      instagram = (
        <a className="follow-item-small instagram" href={content.instagram} target="_blank">
          <li className="followIcon">
            <ModSvg url={require('static/img/svg/icon_instagram.svg')} id="icon" />
          </li>
        </a>
      )
    }

    if (content.facebook !== '') {
      count += 1

      facebook = (
        <a className="follow-item-small facebook" href={content.facebook} target="_blank">
          <li className="followIcon">
            <ModSvg url={require('static/img/svg/icon_facebook.svg')} id="icon" />
          </li>
        </a>
      )
    }

    return (
      <div className={'block-follow-wrapper bg_' + pageColor}>
        <ul className={'block-follow item-' + count}>
          <a className="follow-item-big">
            <li className="followUsIcon" />
            <p className="followUsText typo-h3">
              <FormattedMessage id="blockLocationDetails.followUs" defaultMessage="Follow us on" />
            </p>
          </a>
          {twitter}
          {instagram}
          {facebook}
        </ul>
      </div>
    )
  }
}
