import * as React from 'react'
import * as svg4everybody from 'svg4everybody'
import NavLink from '../components/common/NavLink'
import {hasContent, addClassToElement, removeClassFromElement} from '../common/DDUtil'
import {isArticleActiveNow, searchNavigationNodeByUrl} from '../caas/CaasHelperTools'
import ModSvg from '../components/common/ModSvg'
import ModAnchorTag from './common/ModAnchorTag'

interface ModNavigationProps {
  currentLanguage: string
  navigationTree: any
  pathname: string
  config: any
  pageColor: string
}

interface ModNavigationState {
  naviOpen: boolean
  openNodeId: any
}

export default class ModNavigation extends React.Component<ModNavigationProps, ModNavigationState> {
  constructor(props) {
    super(props)

    this.state = {
      naviOpen: false,
      openNodeId: null,
    }

    this.toggleSubNav = this.toggleSubNav.bind(this)
    this.toggleNavigation = this.toggleNavigation.bind(this)
    this.closeNavigation = this.closeNavigation.bind(this)
    this.createNavigationNode = this.createNavigationNode.bind(this)
    this.createMetaNavigation = this.createMetaNavigation.bind(this)
    this.removeLanguageNavigationNode = this.removeLanguageNavigationNode.bind(this)
  }

  setStateBy(replaceObjects) {
    this.setState(Object.assign({}, this.state, replaceObjects))

    // Set App Class if menu is open
    if (typeof window !== 'undefined') {
      let app = window.document.getElementById('app')
      let content = document.getElementById('content')
      let footer = document.getElementById('footer')
      if (!this.state.naviOpen) {
        addClassToElement(app, 'navigation-open')
        addClassToElement(content, 'hide-content')
        addClassToElement(footer, 'hide-content')
      } else {
        removeClassFromElement(app, 'navigation-open')
        removeClassFromElement(content, 'hide-content')
        removeClassFromElement(footer, 'hide-content')
      }
    }
  }

  toggleSubNav(e) {
    const navButton = e.target
    if (navButton) {
      let id = navButton.dataset.id
      if (id === this.state.openNodeId) {
        id = null
      }

      this.setStateBy({
        openNodeId: id,
      })
    }
  }

  toggleNavigation(e) {
    this.setStateBy({
      naviOpen: !this.state.naviOpen,
      openNodeId: null,
    })
  }

  closeNavigation(e) {
    if (this.state.naviOpen === true) {
      this.setStateBy({
        naviOpen: false,
      })
    }
  }

  createNavigationNode(nodes, isTopNav, pathname, currentLanguage, activeNode) {
    function isNodeVisible(node, i18n) {
      if (!i18n.showInMenu) {
        return false
      }
      if (hasContent(node.children)) {
        return true
      }
      if (node.content) {
        return isArticleActiveNow(node.content, currentLanguage)
      }
      return true
    }

    if (!(nodes instanceof Array) || nodes.length < 1) {
      return null
    }
    let d = nodes.reduce((prevItem, item, index) => {
      if (!item.i18n.hasOwnProperty(currentLanguage)) {
        return prevItem
      }
      const i18n = item.i18n[currentLanguage]
      if (!isNodeVisible(item, i18n)) {
        return prevItem
      }

      if (isTopNav) {
        const hasActiveChild = item.children.find((item) => {
          return activeNode && item.id === activeNode.id
        })

        let openNode = this.state.openNodeId === item.id
        if (openNode === false && hasActiveChild && !this.state.openNodeId) {
          openNode = true
        }

        const validChild = item.children.find((node) => {
          if (node.i18n.hasOwnProperty(currentLanguage)) {
            return node.i18n[currentLanguage].showInMenu
          }
        })
        if (!validChild) {
          prevItem.push(
            <li key={index} className="navigation-item-container">
              <div data-id={item.id} className="navigation-top">
                <ModSvg url={require('static/img/svg/but_arrow_navi.svg')} id="icon" />
                <NavLink to={i18n.relativeUrl} onClick={this.closeNavigation}>
                  <span data-id={item.id}>{i18n.label}</span>
                </NavLink>
              </div>
            </li>
          )
        } else {
          prevItem.push(
            <li key={index} className={'navigation-item-container' + (openNode ? ' is-open' : '')}>
              <div
                className={'navigation-top' + (openNode ? ' is-open' : '')}
                onClick={this.toggleSubNav}
                data-id={item.id}
              >
                <ModSvg url={require('static/img/svg/but_arrow_navi.svg')} id="icon" />
                <span data-id={item.id}>{i18n.label}</span>
              </div>
              {this.createNavigationNode(item.children, false, null, currentLanguage, activeNode)}
            </li>
          )
        }
      } else {
        prevItem.push(
          <li key={index}>
            <NavLink
              className="icon icon-but_arrow_subnav_black"
              to={i18n.relativeUrl}
              onClick={this.closeNavigation}
            >
              <span>– {i18n.label}</span>
            </NavLink>
          </li>
        )
      }

      return prevItem
    }, [])

    return <ul className={isTopNav ? 'navigation-nodes' : 'navigation-sub-nodes'}>{d}</ul>
  }

  createMetaNavigation(nodes, rootNode, currentLanguage, pathname) {
    let languageNodes = []
    let i = 0
    for (let langKey in rootNode.i18n) {
      if (rootNode.i18n.hasOwnProperty(langKey)) {
        let lang = rootNode.i18n[langKey]
        if (hasContent(lang.label)) {
          let langTranslation =
            this.props.config.websiteSettings.translations.translations[lang.slug]

          let languageSpacer

          if (i > 0) {
            languageSpacer = <div className="language-slash">/ </div>
          }

          if (langTranslation['navigation.langKey']) {
            languageNodes.push(
              <li key={langKey} className="language">
                <NavLink to={lang.relativeUrl} onClick={this.closeNavigation}>
                  {langTranslation['navigation.langKey']}
                </NavLink>
              </li>
            )
          } else {
            languageNodes.push(
              <li key={langKey} className="language">
                {languageSpacer}
                <NavLink to={lang.relativeUrl} onClick={this.closeNavigation}>
                  {lang.label}
                </NavLink>
              </li>
            )
          }
          i++
        }
      }
    }

    let metaNavigation = []
    if (nodes) {
      nodes.map((item, index) => {
        let label = ''
        if (item.i18n.hasOwnProperty(currentLanguage)) {
          const i18n = item.i18n[currentLanguage]
          label = i18n.title
        }

        metaNavigation.push(
          <li key={index} onClick={this.closeNavigation} className="metaNavigationOpen">
            <ModAnchorTag linkObject={item.link} pathname={pathname}>
              <div>{label}</div>
            </ModAnchorTag>
          </li>
        )
      })
    }

    return (
      <div className="navigation-meta">
        <div className="navigation-meta-content">
          <ul className="navigation-languages">{languageNodes}</ul>
          <ul className="navigation-meta-links">{metaNavigation}</ul>
        </div>
      </div>
    )
  }

  removeLanguageNavigationNode(nodes, currentLanguage) {
    if (!(nodes instanceof Array)) {
      return null
    }
    for (let key in nodes) {
      if (nodes.hasOwnProperty(key)) {
        let node = nodes[key]
        if (node.slug === currentLanguage) {
          return node
        }
      }
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      svg4everybody({
        polyfill: true,
      })
    }
  }

  render() {
    const {navigationTree, currentLanguage, pathname, config, pageColor} = this.props

    if (!(navigationTree && navigationTree.children && hasContent(currentLanguage))) {
      return null
    }

    const activeNode = searchNavigationNodeByUrl(navigationTree, pathname, currentLanguage)
    const mainNavigation = this.createNavigationNode(
      navigationTree.children,
      true,
      pathname,
      currentLanguage,
      activeNode
    )
    const metaNavigation = this.createMetaNavigation(
      config.websiteSettings.metaNavigation,
      navigationTree,
      currentLanguage,
      pathname
    )

    return (
      <div
        id="navigation"
        className={this.state.naviOpen ? 'is-expanded bg_' + pageColor : 'bg_' + pageColor}
      >
        <div className="navigation-header">
          <div className="navigation-img">
            <a href="/">
              <div
                className={
                  this.state.naviOpen
                    ? 'palavrion-logo is-expanded'
                    : 'palavrion-logo bg_' + pageColor
                }
              >
                <ModSvg url={require('static/img/svg/palavrion_logo.svg')} id="icon" />
              </div>

              <div className="palavrion-logo-sub">
                <ModSvg url={require('static/img/svg/palavrion_logo_sub.svg')} id="icon" />
              </div>
            </a>
          </div>

          <button
            aria-label="menu button"
            className={'navigation-burger bg_' + pageColor}
            onClick={this.toggleNavigation}
          >
            <ModSvg url={require('static/img/svg/but_burger.svg')} id="icon" />
          </button>

          <button
            aria-label="close menu"
            className="navigation-close icon"
            onClick={this.toggleNavigation}
          >
            <ModSvg url={require('static/img/svg/but_close.svg')} id="icon" />
          </button>
        </div>
        <div className="navigation-content">
          <div className="navigation-main">{mainNavigation}</div>

          {metaNavigation}
        </div>
      </div>
    )
  }
}
