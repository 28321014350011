import * as React from 'react'

export interface ModBlockSpacerProps {
  content: {
    value: number
  }
}

export default class ModBlockSpacer extends React.Component<ModBlockSpacerProps> {
  render() {
    const {content} = this.props
    let divStyle = {
      height: content.value + 'px',
    }
    return <div className="block-spacer" style={divStyle} />
  }
}
