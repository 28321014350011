import {NavigationNode, Page, IVoConfig} from '../interfaces/Interfaces'

const {isInt, isEmptyObject, stripSlashes} = require('common/DDUtil')
const {tags} = require('../vo/VoConfig')

/**
 *
 * @param page
 * @param currentLanguage
 * @returns {boolean}
 */
export function isArticleActiveNow(page: Page, currentLanguage: string) {
  if (!page) {
    return false
  }

  const i18n = page.i18n.hasOwnProperty(currentLanguage) ? page.i18n[currentLanguage] : null
  if (!(i18n && i18n.isLive)) {
    return false
  }

  let now = new Date().getTime()
  if (page.expiryDate !== 0 && now > page.expiryDate * 1000) {
    return false
  }

  if (page.publishDate !== 0 && now < page.publishDate * 1000) {
    return false
  }
  return true
}

/**
 * searches an navigation node by his url and returns his id or null if not found
 * @param node
 * @param searchRelativeUrl
 * @param currentLanguage
 * @returns String|null
 */
export function searchNavigationNodeByUrl(
  node: NavigationNode,
  searchRelativeUrl,
  currentLanguage
): NavigationNode | null {
  if (!(node && node.hasOwnProperty('i18n') && node.hasOwnProperty('children'))) return null

  if (currentLanguage) {
    // find by provided language
    const i18n = node.i18n.hasOwnProperty(currentLanguage) ? node.i18n[currentLanguage] : null
    if (i18n && stripSlashes(i18n.relativeUrl) === stripSlashes(searchRelativeUrl)) {
      return node
    }
  } else {
    const found = Object.entries(node.i18n).find((item) => {
      const [langKey, i18n] = item
      return stripSlashes(i18n.relativeUrl) === langKey + '/' + stripSlashes(searchRelativeUrl)
    })
    if (found) {
      return node
    }
  }

  for (let childNode of Object.values(node.children)) {
    const foundNode = searchNavigationNodeByUrl(childNode, searchRelativeUrl, currentLanguage)
    if (foundNode) {
      return foundNode
    }
  }
}

export function searchNavigationNodeById(
  navigationNode: NavigationNode,
  nodeId: string
): NavigationNode | null {
  if (!(nodeId && navigationNode && navigationNode.id)) {
    return null
  }

  if (navigationNode.id === nodeId) {
    return navigationNode
  }

  for (let childNavigationNode of Object.values(navigationNode.children)) {
    const foundNode = searchNavigationNodeById(childNavigationNode, nodeId)
    if (foundNode) {
      return foundNode
    }
  }
}

export function getArticleByNodeId(pagePool: Page[], nodeId: string): Page | null {
  if (!pagePool) {
    return null
  }
  for (let page of Object.values(pagePool)) {
    if (page.navigationNode === nodeId) {
      return page
    }
  }
  return null
}

/**
 * replaces media domain in the url field for nested results
 * @param voConfig
 * @param result
 */
export function replaceMediaDomainRecursive(voConfig: IVoConfig, result): void {
  if (!(typeof result === 'object')) {
    return
  }
  for (let key in result) {
    if (result.hasOwnProperty(key)) {
      let item = result[key]
      if (key === 'cloudinaryMedia' && item.publicId && item.url && item.format) {
        item.url = `https://${voConfig.publicConfig.mediaDomain}/image/upload/${item.publicId}.${item.format}`
      }
      if (item instanceof Array) {
        replaceMediaDomainRecursive(voConfig, item)
      }
      if (typeof item === 'object') {
        replaceMediaDomainRecursive(voConfig, item)
      }
    }
  }
}

/**
 * recursively resolves all outgoing references starting by the traverseObj.
 * @param voConfig
 * @param navigationTree object which contains all navigation nodes
 * @param apiResult complete result object from a graphFlow query
 * @param traverseObj current object to analyze
 * @param karmaModelTypeId karma model type ID of traverseObj
 * @param path of traverseObj in the current karma model
 */
export function resolveAllGraphFlowReferences(
  voConfig: IVoConfig,
  navigationTree: NavigationNode,
  apiResult,
  traverseObj,
  karmaModelTypeId: string,
  path: (string | number)[] = []
) {
  if (!(traverseObj && (typeof traverseObj === 'object' || typeof traverseObj === 'string'))) {
    return traverseObj
  }

  function deepFind(obj, paths) {
    let current = obj
    for (let i = 0; i < paths.length; ++i) {
      if (current[paths[i]] === undefined) {
        return undefined
      } else {
        current = current[paths[i]]
      }
    }
    return current
  }

  const modelDefinition = voConfig.modelDefinitions[karmaModelTypeId] // the complete model definition
  let modelDefinitionByPath = deepFind(modelDefinition, path)
  if (!modelDefinitionByPath) {
    // No Model Definition found for the key. May be the case by _id or _type keys
    return traverseObj
  }
  let karmaPropertyType = Object.keys(modelDefinitionByPath)[0] // Like string, struct, list etc.

  if (karmaPropertyType === 'or') {
    if (isInt(traverseObj) || isEmptyObject(traverseObj)) {
      return traverseObj
    }
    if (traverseObj.constructor === Object) {
      let resolvedOrType = false
      for (let i = 0; i <= modelDefinitionByPath.or.length; i++) {
        const orItem = modelDefinitionByPath.or[i]
        const [type, object] = Object.entries(orItem)[0]
        if (type === 'union') {
          let foundType = Object.keys(object).find((item) => {
            return traverseObj.hasOwnProperty(item)
          })
          if (foundType) {
            resolvedOrType = true
            path = path.concat([karmaPropertyType], i)
            modelDefinitionByPath = modelDefinitionByPath[karmaPropertyType][i]
            karmaPropertyType = Object.keys(modelDefinitionByPath)[0]
            break
          }
        }
      }
      if (!resolvedOrType) {
        return traverseObj
      }
    } else {
      // TODO we don't know which or case it could be and therefore just abort
      return traverseObj
    }
  }

  if (karmaPropertyType === 'annotation') {
    path = path.concat([karmaPropertyType, 'model'])
    modelDefinitionByPath = modelDefinitionByPath[karmaPropertyType].model
    karmaPropertyType = Object.keys(modelDefinitionByPath)[0]
  }

  if (karmaPropertyType === 'optional') {
    path = path.concat(['optional'])
    modelDefinitionByPath = modelDefinitionByPath[karmaPropertyType]
    karmaPropertyType = Object.keys(modelDefinitionByPath)[0]
  }

  if (
    traverseObj instanceof Array &&
    (karmaPropertyType === 'list' || karmaPropertyType === 'set')
  ) {
    // handles karma list property
    traverseObj = traverseObj.map((arrayItem) => {
      return resolveAllGraphFlowReferences(
        voConfig,
        navigationTree,
        apiResult,
        arrayItem,
        karmaModelTypeId,
        path.concat([karmaPropertyType])
      )
    })
  } else if (
    typeof traverseObj === 'object' &&
    (karmaPropertyType === 'struct' || karmaPropertyType === 'union')
  ) {
    // handles karma struct and union property
    for (let [key, value] of Object.entries(traverseObj)) {
      if (traverseObj.hasOwnProperty(key)) {
        traverseObj[key] = resolveAllGraphFlowReferences(
          voConfig,
          navigationTree,
          apiResult,
          value,
          karmaModelTypeId,
          path.concat([karmaPropertyType, key])
        )
      }
    }
  } else if (typeof traverseObj === 'object' && karmaPropertyType === 'map') {
    // handles karma map and union property
    for (let mapKey of Object.keys(traverseObj)) {
      traverseObj[mapKey] = resolveAllGraphFlowReferences(
        voConfig,
        navigationTree,
        apiResult,
        traverseObj[mapKey],
        karmaModelTypeId,
        path.concat([karmaPropertyType])
      )
    }
  } else if (typeof traverseObj === 'string' && karmaPropertyType === 'ref') {
    // handle karma references
    const refModelId = modelDefinitionByPath[karmaPropertyType]
    if (refModelId === voConfig.getContentIdByTag(tags.navigationNode) && navigationTree) {
      traverseObj = searchNavigationNodeById(navigationTree, traverseObj)
    } else {
      if (apiResult.hasOwnProperty(refModelId)) {
        let modelPool = apiResult[refModelId]
        if (modelPool.hasOwnProperty(traverseObj)) {
          let recordId = traverseObj
          traverseObj = resolveAllGraphFlowReferences(
            voConfig,
            navigationTree,
            apiResult,
            modelPool[traverseObj],
            refModelId
          )
          traverseObj['_id'] = recordId
          traverseObj['_type'] = refModelId
        }
      }
    }
  }
  return traverseObj
}

/**
 * change the server result to a nested object
 * @param nodePool
 * @param pagePool
 * @param nodeIds array of node id's
 * @param parentNode
 * @returns {*}
 */
export function createNodes(
  nodePool: NavigationNode[],
  pagePool: Page[],
  nodeIds,
  parentNode: NavigationNode | string
): NavigationNode {
  return nodeIds.reduce((previousValue, nodeId) => {
    if (!nodePool[nodeId]) {
      return previousValue
    }
    const node = nodePool[nodeId]

    let i18n = {}
    for (let [langKey, navigationNode] of Object.entries(node.i18n)) {
      i18n[langKey] = {
        slug: navigationNode.slug,
        showInMenu: navigationNode.showInMenu,
        label: navigationNode.label,
      }

      if (parentNode === 'root') {
        i18n[langKey].relativeUrl = '/' + langKey
      } else if ((<NavigationNode>parentNode).i18n) {
        if ((<NavigationNode>parentNode).i18n.hasOwnProperty(langKey)) {
          i18n[langKey].relativeUrl =
            (<NavigationNode>parentNode).i18n[langKey].relativeUrl + '/' + navigationNode.slug
        }
      }
    }

    let enrichedNode: NavigationNode = {
      id: nodeId,
      i18n: i18n,
      children: node.children,
      content: null,
    }

    enrichedNode.children = createNodes(nodePool, pagePool, node.children, enrichedNode)
    enrichedNode.content = getArticleByNodeId(pagePool, nodeId)

    previousValue.push(enrichedNode)
    return previousValue
  }, [])
}
