import * as React from 'react'
import * as ReactDOM from 'react-dom'
import ScriptCache from '../../common/maps/ScriptCache'
import MarkerClusterer from '../../common/maps/markerclusterer'
import {injectIntl} from 'react-intl'
import {mapStyles} from '../common/MapStyle'
import PropTypes from 'prop-types'

const {getLocations, getAllLocations} = require('backend/locations')

/**
 * Resources
 * https://developers.google.com/maps/articles/toomanymarkers
 * https://developers.google.com/my-business/content/location-data#batch_get
 * source http://group.movenpick.com/standorte/
 */
class ModBlockLocations extends React.Component {
  constructor(props) {
    super(props)

    this.divMap = null
    this.scriptCache = null
    this.markerClusterer = null
    this.map = null
    this.infowindow = null

    this.state = {
      locations: null,
      selectedBrand: 'P',
    }

    this.refreshMarkers = this.refreshMarkers.bind(this)
    this.refreshPosition = this.refreshPosition.bind(this)
    this.newMarker = this.newMarker.bind(this)

    const {formatMessage} = this.props.intl

    this.brands = {
      P: {
        translation: formatMessage({
          id: 'blockLocations.palavrion',
          defaultMessage: 'Palavrion',
        }),
        pin: require('static/img/icon_palavrion_01.png'),
        location: {
          lat: 51.3411362,
          long: 9.3502756,
          zoom: 5,
        },
      },
    }
  }

  newMarker(location) {
    const {formatMessage} = this.props.intl

    if (!location) {
      return null
    }
    if (!this.brands.hasOwnProperty(location.categoryId)) {
      return null
    }

    const latLng = new google.maps.LatLng(location.latitude, location.longitude)
    const brand = this.brands[location.categoryId]
    let address = location.addressLines ? location.addressLines.join('<br />') + '<br />' : ''
    address += location.postalCode + ' ' + location.locality + '<br />'
    const openingHours = ''
    const i18nOpenWebsite = formatMessage({
      id: 'blockLocations.openWebsite',
      defaultMessage: 'Open Website',
    })
    const i18nPhone = formatMessage({
      id: 'blockLocations.phone',
      defaultMessage: 'Phone',
    })
    const content = `
    <b>${location.locationName}</b><br>
    <span>${address}</span><br>
    <span>${i18nPhone}. ${location.primaryPhone}</span><br>
    <span>${openingHours}</span><br><br>
    <a href="${location.mapsUrl}" target="_blank" rel="noopener">${i18nOpenWebsite}</a>`

    const infowindow = new google.maps.InfoWindow({
      content: content,
      size: new google.maps.Size(70, 50),
    })

    let marker = new google.maps.Marker({
      position: latLng,
      draggable: false,
      icon: new google.maps.MarkerImage(brand.pin, new google.maps.Size(30, 45)),
      title: location.locationName,
    })

    google.maps.event.addListener(marker, 'click', () => {
      if (this.infowindow) {
        this.infowindow.close()
      }
      infowindow.open(this.map, marker)
      this.infowindow = infowindow
    })

    return marker
  }

  refreshMarkers() {
    if (
      !(typeof google !== 'undefined' && typeof google.maps !== 'undefined' && this.state.locations)
    ) {
      // Map is not loaded yet
      return
    }

    if (!this.map.getBounds()) {
      return
    }

    const {locations, selectedBrand} = this.state
    let markers = []

    for (let key in locations) {
      if (locations.hasOwnProperty(key)) {
        const location = locations[key]
        if (selectedBrand === 'P' || selectedBrand === location.categoryId) {
          const marker = this.newMarker(location)
          if (marker) {
            markers.push(marker)
          }
        }
      }
    }

    let options = {
      maxZoom: 13,
      gridSize: 50,
      styles: [
        {
          url: this.brands[selectedBrand].pin,
          height: 45,
          width: 30,
          anchor: [7, 0],
          textColor: '#ffffff',
          textSize: 13,
        },
      ],
    }

    if (this.markerClusterer) {
      this.markerClusterer.clearMarkers()
      this.markerClusterer.setStyles(options.styles)
      this.markerClusterer.addMarkers(markers, false)
    } else {
      this.markerClusterer = new MarkerClusterer(this.map, markers, options)
    }
  }

  refreshPosition() {
    if (!this.map) {
      return
    }

    const {selectedBrand} = this.state
    const {location} = this.brands[selectedBrand]

    if (!location) {
      return
    }
    this.map.setZoom(location.zoom)
    this.map.setCenter(new google.maps.LatLng(location.lat, location.long))
  }

  loadMap() {
    if (!(typeof google !== 'undefined' && typeof google.maps !== 'undefined')) {
      // Map is not loaded yet
      return
    }

    //************************************************************************************
    // Init Map
    const {selectedBrand} = this.state
    const location = this.brands[selectedBrand].location

    const node = ReactDOM.findDOMNode(this.divMap)
    const {lat, long, zoom} = location
    const center = new google.maps.LatLng(lat, long)
    const mapConfig = Object.assign(
      {},
      {
        center: center,
        zoom: zoom,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        },
        panControl: false,
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
        },
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      }
    )

    if (!node) {
      return
    }

    let map = new google.maps.Map(node, mapConfig)
    map.setOptions({styles: mapStyles})
    this.map = map

    this.refreshMarkers()
  }

  componentDidMount() {
    const {currentLanguage, voConfig} = this.props
    const scriptName = 'googleMaps_' + currentLanguage
    let script = {}
    script[scriptName] =
      'https://maps.googleapis.com/maps/api/js?key=' +
      voConfig.publicConfig.googleMapsApiKey +
      '&language=' +
      currentLanguage +
      '&libraries=places,language'

    if (typeof window !== 'undefined') {
      this.scriptCache = ScriptCache(window, script)

      getAllLocations(voConfig.publicConfig)
        .then((result) => {
          this.setState({locations: result})
        })
        .catch(console.error)

      this.scriptCache[scriptName].onLoad((err, tag) => {
        this.loadMap()
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.refreshMarkers()
    this.refreshPosition()
  }

  render() {
    const {locations, selectedBrand} = this.state
    const {currentLanguage} = this.props

    return (
      <div className="block-locations">
        <div className="mapwrapper">
          <div className="map" ref={(ref) => (this.divMap = ref)} />
        </div>
      </div>
    )
  }
}

ModBlockLocations.propTypes = {
  content: PropTypes.object,
  currentLanguage: PropTypes.string.isRequired,
  voConfig: PropTypes.object.isRequired,
}

export default injectIntl(ModBlockLocations)
