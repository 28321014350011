import * as React from 'react'
import ModAnchorTag from '../../components/common/ModAnchorTag'
import ModImgTag from '../../components/common/ModImgTag'
import ModIdangerousSwiper from '../../components/common/ModIdangerousSwiper'

interface ModBlockTestimonialsProps {
  content: any
  pathname: string
}

export default class ModBlockTestimonials extends React.Component<ModBlockTestimonialsProps, {}> {
  private onClickProductImage: any

  render() {
    const {content, pathname} = this.props
    const quotes = content.quotes.map((item, index) => {
      if (!item._i18nCL) {
        return null
      }
      const i18n = item._i18nCL

      return (
        <div
          className={content.quotes.length === 1 ? 'swiper-slide-active' : 'swiper-slide'}
          key={index}
          data-index={index}
        >
          <div className="block-testimonials">
            <div className="block-testimonial" key={index}>
              <div className="block-testimonials-image-container">
                <ModAnchorTag linkObject={item.link} pathname={pathname}>
                  <ModImgTag imgObject={item.image} width={760} height={475} />
                </ModAnchorTag>
              </div>

              <div
                className={
                  content.quotes.length === 1
                    ? 'block-testimonials-text-wrapper'
                    : 'block-testimonials-text-wrapper slider'
                }
              >
                <div className="block-testimonials-text-container">
                  <h3 className="typo-h3">{i18n.quote}</h3>
                  <h2 className="typo-h2">
                    {i18n.name} {i18n.jobTitle}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })

    let testimonials

    if (content.quotes.length === 1) {
      testimonials = quotes
    } else {
      testimonials = (
        <ModIdangerousSwiper
          id={'block_slider_' + this.props.content._id}
          hasNavigation={true}
          sliderHome={'but_arrow_slider.svg'}
          sliderHomeHover={'but_arrow_slider_b.svg'}
          hasPagination={false}
          maxPaginationCount={10}
          hasFullScreen={false}
          hasSlideNumber={false}
          textSlideshowBlock={false}
        >
          {quotes}
        </ModIdangerousSwiper>
      )
    }

    return <div className="block-testimonials-slider">{testimonials}</div>
  }
}
