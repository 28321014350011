import * as React from 'react'
import {Link} from 'react-router'

interface NaviLinkProps {
  className?: any
  onClick?: any
  to: {
    pathname?: string
    hash?: string
    i18n?: any
    query?: any
  }
}

export default class NavLink extends React.Component<NaviLinkProps, {}> {
  render() {
    return <Link {...this.props} activeClassName="active" />
  }
}
