import {IVoConfig} from '../interfaces/Interfaces'

export default class VoConfig implements IVoConfig {
  // MODEL TYPES
  contentType

  // KARMA MODEL DEFINITIONS
  modelDefinitions

  websiteSettings

  // PROJECT SETTINGS WHICH NEVER SHOULD CONTAIN SECRETS!
  publicConfig

  domain
  cookies: any

  // Enables the edit buttons which link to the karma GUI
  editMode: boolean

  constructor() {
    this.contentType = null
    this.modelDefinitions = null
    this.websiteSettings = null
    this.publicConfig = null
    this.domain = null
    this.cookies = {}
    this.editMode = false
    this.getContentIdByTag = this.getContentIdByTag.bind(this)
  }

  getContentIdByTag(tag) {
    return this.contentType[tag]
  }
}

export const tags = {
  navigationNode: 'navigationNode',
  media: 'media',
  footer: 'footer',
  blockRichText: 'blockRichText',
  blockTitle: 'blockTitle',
  blockTeaser: 'blockTeaser',
  blockTeaserShared: 'blockTeaserShared',
  blockTeaserSpot: 'blockTeaserSpot',
  blockSpacer: 'blockSpacer',
  blockSlider: 'blockSlider',
  blockHeaderSlider: 'blockHeaderSlider',
  blockImageText: 'blockImageText',
  blockSnippet: 'blockSnippet',
  blockInstagramLightWidget: 'blockInstagramLightWidget',
  blockHeader: 'blockHeader',
  blockTwoImagesText: 'blockTwoImagesText',
  blockGrid: 'blockGrid',
  blockEvents: 'blockEvents',
  blockTestimonials: 'blockTestimonials',
  blockPoster: 'blockPoster',
  blockLocations: 'blockLocations',
  blockGridFourColumns: 'blockGridFourColumns',
  blockSmallTeaser: 'blockSmallTeaser',
  blockSmallTeaserShared: 'blockSmallTeaserShared',
  blockSharing: 'blockSharing',
  blockFollow: 'blockFollow',
  blockIllustrationAnimation: 'blockIllustrationAnimation',
  blockPartnerPromotion: 'blockPartnerPromotion',
  blockCorporateHome: 'blockCorporateHome',
  blockVimeo: 'blockVimeo',
  blockEventsView: 'blockEventsView',
  blockTextSlideShow: 'blockTextSlideShow',
  blockGridThreeColumns: 'blockGridThreeColumns',
  overlayTwoImagesText: 'overlayTwoImagesText',
  blockIssuu: 'blockIssuu',
  blockLocationEquipment: 'blockLocationEquipment',
  templateLocationMarcheMovenpick: 'templateLocationMarcheMovenpick',
  templateBlockListMarcheMovenpick: 'templateBlockListMarcheMovenpick',
  templateBlockListWhiteMonkey: 'templateBlockListWhiteMonkey',
  templateBlockListCorporate: 'templateBlockListCorporate',
  templateBlockListBeefGrillBar: 'templateBlockListBeefGrillBar',
  templateBlockListPalavrion: 'templateBlockListPalavrion',
  locationPage: 'locationPage',
  website: 'website',
  translations: 'translations',
  domain: 'domain',
  page: 'page',
  location: 'location',
  widgetBookatable: 'widgetBookatable',
  widgetTripadvisor: 'widgetTripadvisor',
  widgetFacebook: 'widgetFacebook',
  overlay: 'overlay',
}
