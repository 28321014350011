import {log} from './common/Log'

module.exports.getPublicSettings = async function getSettings() {
  if (typeof window === 'undefined') {
    const settings = {
      caasEndpoint: process.env.KARMA_ENDPOINT,
      caasEditorUrl: process.env.KARMA_EDITOR_URL,
      user: process.env.KARMA_USER,
      pwd: process.env.KARMA_PWD,
      caasDb: process.env.KARMA_DATABASE,
      mediaDomain: process.env.MEDIA_DOMAIN,
      googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
      googleAnalyticsKey: process.env.GOOGLE_ANALYTICS_KEY,
    }
    log('**************************************************************************************')
    log('caasEndpoint:', settings.caasEndpoint)
    log('caasDb:', settings.caasDb)
    log('user:', settings.user)

    return settings
  } else {
    const url = 'http://localhost:9090/api/public_settings.json'
    const response = await fetch(url)
    return await response.json()
  }
}
