import * as React from 'react'
import ModRichText from '../components/common/ModRichText'
import ModAnchorTag from '../components/common/ModAnchorTag'
import ModImgTag from '../components/common/ModImgTag'
import {newsLetterFormLinks, newsLetterStrings} from '../common/Languages'

interface ModFooterProps {
  content: {
    internalDescription: string
    ownerGroup: string
    logos: any
    socialMedia: any
    i18n: {
      [key: string]: {
        title: any
        blockCenter: any
        blockLeft: any
        blockRight: any
        logosTitle: string
        socialMediaTitle: string
        hideNewsletter: boolean
      }
    }
    _i18nCL: {
      title: any
      blockCenter: any
      blockLeft: any
      blockRight: any
      logosTitle: string
      socialMediaTitle: string
      hideNewsletter: boolean
    }
  }
  currentLanguage: string
  pathname: string
}

export default class ModFooter extends React.Component<ModFooterProps> {
  render() {
    const {content, pathname, currentLanguage} = this.props

    if (!content) {
      return null
    }

    if (!content._i18nCL) {
      return null
    }
    const i18n = content._i18nCL

    const newsLetterLink = newsLetterFormLinks[currentLanguage]
    const newsLetterString = newsLetterStrings[currentLanguage]

    const socialMedia = content.socialMedia.reduce((prevItem, item, index) => {
      if (item && item.hasOwnProperty('image') && item.image instanceof Object) {
        prevItem.push(
          <li key={index} className="socialMedia-links">
            <ModAnchorTag linkObject={item.link} pathname={pathname}>
              <ModImgTag imgObject={item.image} />
            </ModAnchorTag>
          </li>
        )
      }
      return prevItem
    }, [])

    const logos = content.logos.reduce((prevItem, item, index) => {
      let caption = null
      if (item.i18n.hasOwnProperty(currentLanguage)) {
        caption = <p>{item.i18n[currentLanguage].caption}</p>
      }
      prevItem.push(
        <li key={index}>
          <ModAnchorTag linkObject={item.link} pathname={pathname}>
            <ModImgTag imgObject={item.imageHover} width={122} className="footer-logo-colored" />
            <ModImgTag imgObject={item.image} width={122} className="footer-logo-grayscale" />
          </ModAnchorTag>
          {caption}
        </li>
      )
      return prevItem
    }, [])

    let newsletter = null
    if (!i18n.hideNewsletter) {
      newsletter = (
        <a className="footer-newsletter" href={newsLetterLink} target="_blank" rel="noopener">
          {newsLetterString}
          <img src={require('static/img/svg/but_menu_lo.svg')} alt="" />
        </a>
      )
    }

    return (
      <footer id="footer">
        <div className="footer-content-wrapper">
          <div className="footer-title typo-h3">{i18n.title}</div>

          <div className="footer-content">
            <div className="footer-content-left">
              <ModRichText richText={i18n.blockLeft} pathname={pathname} />
            </div>
            <div className="footer-content-center">
              <ModRichText richText={i18n.blockCenter} pathname={pathname} />
            </div>

            <div className="footer-content-newsletter">
              <ModRichText richText={i18n.blockRight} pathname={pathname} />
              {newsletter}
            </div>

            <div className="footer-content-right">
              <div className="footer-content-socialmedia">
                <h5 className="footer-content-socialmedia-title typo-h5">
                  {i18n.socialMediaTitle}
                </h5>
                <ul>{socialMedia}</ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-logo-wrapper">
          <div className="footer-logos">
            <p>{i18n.logosTitle}</p>
            <ul>{logos}</ul>
          </div>
        </div>
      </footer>
    )
  }
}
