import * as React from 'react'
import {FormattedMessage} from 'react-intl'

declare var Cookiebot: any

interface ModBlockInstagramLightWidgetProps {
  content: any
}

export default class ModBlockInstagramLightWidget extends React.Component<ModBlockInstagramLightWidgetProps> {
  componentDidMount() {
    // @ts-ignore
    Cookiebot.runScripts()
  }

  render() {
    const {content} = this.props

    return (
      <div className="block-instagram-light-widget">
        <div className="aspectRatio">
          <div className="cookieconsent-optout-marketing">
            <div className="cookieconsent-optout-marketing-custom">
              <h3>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    Cookiebot.renew()
                  }}
                >
                  <FormattedMessage
                    id="cookiePolicy.acceptMarketing"
                    defaultMessage="Please accept marketing cookies to see this content."
                  />
                </a>
              </h3>
            </div>
          </div>
          <iframe
            className="lightwidget-widget"
            data-cookieblock-src={'/api/widgets/instagram.html?widgetId=' + content.widgetId}
            data-cookieconsent="marketing"
          />
        </div>
      </div>
    )
  }
}
