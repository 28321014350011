import * as React from 'react'
import ModNavigation from './ModNavigation'
import ModFooter from './ModFooter'
import ModContent from './ModContent'
import ModCookiePolicy from './ModCookiePolicy'
import CaasHelper from '../caas/CaasHelper'
import {searchNavigationNodeByUrl} from '../caas/CaasHelperTools'
import VoConfig from '../vo/VoConfig'
import {createMetaFields} from '../common/MetaFields'
import ModModalView from './ModModalView'
import {
  getJsonCookie,
  setJsonCookie,
  addClassToElement,
  removeClassFromElement,
  addCurrentLangRecursive,
} from '../common/DDUtil'
import {getCurrentLanguageOrFallBackByPath} from '../common/Languages'
import {IntlProvider, addLocaleData} from 'react-intl'
import * as de from 'react-intl/locale-data/de'
import * as en from 'react-intl/locale-data/en'

addLocaleData([...de, ...en])

interface ModAppProps {
  params: {
    splat: any
    appState: any
  }
  router: any
  location: {
    pathname: string
    hash: string
  }
}

interface ModAppState {
  config: any
  content: any
  navigationTree: any
  shareIconsVisible: boolean
  window: {
    APP_PROPS: any
  }
}

declare global {
  interface Window {
    APP_PROPS: any
    lastKnownScrollPosition: number
  }
}

export default class ModApp extends React.Component<ModAppProps, ModAppState> {
  private caasHelper: any
  private lastKnownScrollPosition: number

  constructor(props) {
    super(props)

    this.caasHelper = new CaasHelper()
    this.lastKnownScrollPosition = 0
    const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.params.splat)

    if (typeof window !== 'undefined') {
      // runs in frontend
      if (window['APP_PROPS']) {
        this.state = window['APP_PROPS']
        this.state.config.cookies.cookie_consent_dismissed = !!getJsonCookie(
          'cookie_consent_dismissed'
        )
        addCurrentLangRecursive(this.state, currentLanguage)
        this.caasHelper.injectConfig(Object.assign(new VoConfig(), this.state.config))
      }
    } else if (this.props.params.appState) {
      // runs in backend
      this.state = this.props.params.appState
      this.state.config.cookies.cookie_consent_dismissed = true
      addCurrentLangRecursive(this.state, currentLanguage)
    }

    this.checkIfStateIsValid = this.checkIfStateIsValid.bind(this)
    this.setStateAndCurrentLang = this.setStateAndCurrentLang.bind(this)
    this.setHeadMetaInformation = this.setHeadMetaInformation.bind(this)
    this.onCloseModalView = this.onCloseModalView.bind(this)
    this.onGotoHome = this.onGotoHome.bind(this)
    this.hasUpdatedLocation = this.hasUpdatedLocation.bind(this)
    this.hasUpdatedHash = this.hasUpdatedHash.bind(this)
    this.setCookie = this.setCookie.bind(this)
    this.toggleEditMode = this.toggleEditMode.bind(this)
    this.shareIconVisible = this.shareIconVisible.bind(this)
  }

  checkIfStateIsValid() {
    return (
      this.state &&
      this.state.config &&
      this.state.config.websiteSettings &&
      this.state.navigationTree
    )
  }

  setHeadMetaInformation() {
    if (!this.checkIfStateIsValid()) return
    const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)
    const metaFields = createMetaFields(
      this.state.content,
      this.state.config.websiteSettings,
      currentLanguage
    )
    document.title = metaFields.title
  }

  setStateAndCurrentLang(newState) {
    const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)
    addCurrentLangRecursive(newState, currentLanguage)
    this.setState(newState)
  }

  onCloseModalView() {
    this.props.router.push({
      action: 'PUSH',
      hash: '',
      pathname: this.props.location.pathname,
      query: {},
      search: '',
    })
  }

  /**
   * sets a json format cookie by key
   * @param key string
   * @param value object (json)
   */
  setCookie(key, value) {
    let o = {}
    o[key] = value
    const newConfigState = Object.assign({}, this.state.config, {
      cookies: Object.assign({}, this.state.config.cookies, o),
    })
    this.setStateAndCurrentLang({config: newConfigState})
    setJsonCookie(key, value)
  }

  onGotoHome() {
    this.props.router.goBack()
  }

  hasUpdatedLocation(lastProps, nextProps) {
    try {
      if (lastProps.location.pathname !== nextProps.location.pathname) {
        return true
      }
    } catch (e) {}
    return false
  }

  hasUpdatedHash(lastProps, nextProps) {
    try {
      if (lastProps.location.hash !== nextProps.location.hash) {
        return true
      }
    } catch (e) {}
    return false
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.hasUpdatedLocation(this.props, nextProps)) {
      const nextLanguage = getCurrentLanguageOrFallBackByPath(nextProps.params.splat)
      const foundNode = searchNavigationNodeByUrl(
        this.state.navigationTree,
        nextProps.params.splat,
        nextLanguage
      )
      this.caasHelper
        .fetchContentByNavigationNode(this.state.navigationTree, foundNode, nextLanguage)
        .then((content) => {
          this.setStateAndCurrentLang({
            content: content,
            shareIconsVisible: false,
          })
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.setHeadMetaInformation()
    if (
      this.hasUpdatedLocation(prevProps, this.props) ||
      this.hasUpdatedHash(prevProps, this.props)
    ) {
      // this.setState({fadeInNavigation: "displayNone", shareIconsVisible: false})
      this.setState({shareIconsVisible: false})

      if (typeof window !== 'undefined') {
        if (this.props.location.hash) {
          // TODO experimental internal link with anchor support
          setTimeout(() => {
            const divBlock = document.getElementById(
              'anchor_' + this.props.location.hash.substring(1)
            )
            if (divBlock) {
              divBlock.scrollIntoView()
            }
          }, 400)
        } else {
          window.scrollTo(0, 0)
        }
      }
    }
  }

  /**
   * activates edit mode which displays direct link to the karma editor
   */
  toggleEditMode() {
    if (typeof window !== 'undefined') {
      let keysDown = {}
      let switchMode = false

      function isValidKey(key) {
        return key === 'Control' || key === 'Shift' || key === 'E'
      }

      document.addEventListener(
        'keydown',
        (event) => {
          if (isValidKey(event.key)) {
            keysDown[event.key] = true
            if (Object.keys(keysDown).length === 3) {
              switchMode = true
            }
          }
        },
        false
      )
      document.addEventListener(
        'keyup',
        (event) => {
          if (isValidKey(event.key)) {
            delete keysDown[event.key]
            if (switchMode) {
              switchMode = false
              const newConfigState = Object.assign({}, this.state.config, {
                editMode: !this.state.config.editMode,
              })
              this.setStateAndCurrentLang({config: newConfigState})
            }
          }
        },
        false
      )
    }
  }

  componentDidMount() {
    this.toggleEditMode()

    if (!this.state) {
      this.caasHelper
        .fetchAll(this.props.params.splat, null)
        .then((result) => {
          result.config.cookies.cookie_consent_dismissed = !!getJsonCookie(
            'cookie_consent_dismissed'
          )
          this.setStateAndCurrentLang(result)
        })
        .catch((error) => {
          console.error(error.toString())
        })
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', function (e) {
        let scrollPositionY = window.pageYOffset || document.documentElement.scrollTop
        let app = window.document.getElementById('app')

        if (scrollPositionY > 100) {
          addClassToElement(app, 'detached')
        } else {
          removeClassFromElement(app, 'detached')
        }

        if (this.lastKnownScrollPosition > scrollPositionY || scrollPositionY < 44) {
          removeClassFromElement(app, 'menu-hidden')
        } else {
          addClassToElement(app, 'menu-hidden')
        }

        this.lastKnownScrollPosition = scrollPositionY
      })
    }
  }

  shareIconVisible(e) {
    this.setState({shareIconsVisible: !this.state.shareIconsVisible})
  }

  render() {
    if (!this.checkIfStateIsValid()) {
      return (
        <div id="app">
          <p className="loading">loading</p>
        </div>
      )
    }

    const {config, navigationTree, content} = this.state
    const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)
    const translations = config.websiteSettings.translations.translations[currentLanguage]
    const i18n = config.websiteSettings.i18n.hasOwnProperty(currentLanguage)
      ? config.websiteSettings.i18n[currentLanguage]
      : null
    const modalView = (
      <ModModalView
        onClose={this.onCloseModalView}
        caasHelper={this.caasHelper}
        location={this.props.location}
        navigationTree={navigationTree}
        currentLanguage={currentLanguage}
        pathname={this.props.location.pathname}
      />
    )

    let websiteColor = 'black'
    if (content) {
      websiteColor = content.article.websiteColor
    }

    return (
      <IntlProvider locale={currentLanguage} messages={translations}>
        <div id="app" className={websiteColor === 'black' ? 'bg_black' : 'bg_white'}>
          {modalView}
          {/* <ModCookiePolicy i18n={i18n}
                           setCookie={this.setCookie}
                           cookies={this.state.config.cookies}
                           pathname={this.props.location.pathname}/> */}
          <ModNavigation
            navigationTree={navigationTree}
            currentLanguage={currentLanguage}
            pathname={this.props.location.pathname}
            config={config}
            pageColor={websiteColor}
          />
          <ModContent
            config={config}
            content={content}
            caasHelper={this.caasHelper}
            navigationTree={navigationTree}
            currentLanguage={currentLanguage}
            pathname={this.props.location.pathname}
          />
          <ModFooter
            content={config.websiteSettings.footer}
            pathname={this.props.location.pathname}
            currentLanguage={currentLanguage}
          />
        </div>
      </IntlProvider>
    )
  }
}
