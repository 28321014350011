import CaasApi from '../caas/CaasApi'
import {tags} from '../vo/VoConfig'
import {getPublicSettings} from 'publicConfig'

exports.getAllLocations = async function getLocations(publicConfig = null) {
  if (!publicConfig) {
    publicConfig = await getPublicSettings()
  }
  const {caasEndpoint, caasDb, user, pwd} = publicConfig
  const karmaApi = new CaasApi(caasEndpoint, caasDb, user, pwd)
  await karmaApi.signIn()
  return await karmaApi.query({
    all: {
      tag: 'location',
    },
  })
}

exports.getLocations = async function getLocations(publicConfig = null, currentLang = null) {
  if (!publicConfig) {
    publicConfig = await getPublicSettings()
  }
  const {caasEndpoint, caasDb, user, pwd} = publicConfig
  const karmaApi = new CaasApi(caasEndpoint, caasDb, user, pwd)
  await karmaApi.signIn()
  const tagIds = await karmaApi.tags
  let websites = await karmaApi.query({
    mapList: {
      value: {
        all: {
          tag: 'website',
        },
      },
      expression: {
        metarialize: {
          arg: {},
        },
      },
    },
  })

  let promises = websites.map((website) => {
    return getTree(karmaApi, website)
  })

  const response = await Promise.all(promises)

  return response
    .filter((item) => {
      return item.hasOwnProperty(tagIds[tags.location]) && item.hasOwnProperty(tagIds[tags.domain])
    })
    .reduce((accumulator, item) => {
      const website = Object.values(item[tagIds[tags.website]])[0]
      accumulator = accumulator.concat(
        createNodes(tagIds, item, [website.rootNavigationNode], null, currentLang)
      )
      return accumulator
    }, [])
}

async function getTree(karmaApi, website) {
  return await karmaApi.query({
    graphFlow: {
      start: {
        newRef: {
          id: website.id,
          model: website.model,
        },
      },
      flow: [
        {
          from: {
            tag: tags.website,
          },
          backward: [
            {
              tag: tags.domain,
            },
          ],
        },
        {
          from: {
            tag: tags.website,
          },
          forward: [
            {
              tag: tags.navigationNode,
            },
          ],
        },
        {
          from: {
            tag: tags.navigationNode,
          },
          forward: [
            {
              tag: tags.navigationNode,
            },
          ],
        },
        {
          from: {
            tag: tags.navigationNode,
          },
          backward: [
            {
              tag: tags.page,
            },
          ],
        },
        {
          from: {
            tag: tags.page,
          },
          forward: [
            {
              tag: tags.templateLocationMarcheMovenpick,
            },
          ],
        },
        {
          from: {
            tag: tags.templateLocationMarcheMovenpick,
          },
          forward: [
            {
              tag: tags.location,
            },
          ],
        },
      ],
    },
  })
}

function createNodes(tagIds, result, nodeIds, parentNode, currentLang) {
  const nodePool = result[tagIds[tags.navigationNode]]
  const templateLocationMarcheMovenpickPool = result[tagIds[tags.templateLocationMarcheMovenpick]]
  const locationPool = result[tagIds[tags.location]]
  const articlePool = result[tagIds[tags.page]]
  const domain = Object.values(result[tagIds[tags.domain]])[0]

  return nodeIds.reduce((accumulator, nodeId) => {
    if (!nodePool[nodeId]) {
      log('CaasHelper.createNodes: node ' + nodeId + ' not found')
      return accumulator
    }
    const node = nodePool[nodeId]

    node.i18n = Object.entries(node.i18n).reduce((accu, keyVale) => {
      const [langKey, i18n] = keyVale
      if (i18n.slug) {
        if (
          parentNode &&
          parentNode.hasOwnProperty('i18n') &&
          parentNode.i18n.hasOwnProperty(langKey)
        ) {
          i18n.relativeUrl = parentNode.i18n[langKey].relativeUrl + '/' + encodeURI(i18n.slug)
        } else {
          i18n.relativeUrl = '/' + langKey
        }
        accu[langKey] = i18n
      }
      return accu
    }, {})

    accumulator = accumulator.concat(createNodes(tagIds, result, node.children, node, currentLang))

    const article = getArticleByNodeId(articlePool, nodeId)
    if (article && article.content && article.content.templateLocationMarcheMovenpick) {
      const templateLocationMarcheMovenpick =
        templateLocationMarcheMovenpickPool[article.content.templateLocationMarcheMovenpick]
      const location = locationPool[templateLocationMarcheMovenpick.googlyMyBusinessLocation]

      let relativeUrl = Object.entries(node.i18n).reduce(
        (accu, item) => {
          const [lang, i18n] = item
          const validUrlPattern = /^\/[a-z]+\/.+/
          if (validUrlPattern.test(i18n.relativeUrl)) {
            if (currentLang === lang) {
              accu.currentLangPath = i18n.relativeUrl
            }
            accu.fallbackPath = i18n.relativeUrl
          }
          return accu
        },
        {fallbackPath: null, currentLangPath: null}
      )
      relativeUrl = relativeUrl.currentLangPath
        ? relativeUrl.currentLangPath
        : relativeUrl.fallbackPath

      location.frontendLink = `http://${domain.domains[0]}${relativeUrl}`
      location.i18n = Object.entries(templateLocationMarcheMovenpick.i18n).reduce(
        (accumulator, item) => {
          const [key, val] = item
          if (val.hasOwnProperty('title') && val.title) {
            accumulator[key] = {title: val.title}
          }
          return accumulator
        },
        {}
      )
      accumulator.push(location)
    }
    return accumulator
  }, [])
}

function getArticleByNodeId(articlePool, nodeId) {
  if (!articlePool) {
    return null
  }
  for (let article of Object.values(articlePool)) {
    if (article.navigationNode === nodeId) {
      return article
    }
  }
  return null
}
