import * as React from 'react'
import ModRichText from '../../components/common/ModRichText'
import ModImgTag from '../../components/common/ModImgTag'

export interface ModBlockTwoImagesTextProps {
  content: {
    imageLeft: any
    imageRight: any
    _i18nCL: any
  }
  pathname?: string
  pageColor?: string
}

export default class ModBlockTwoImagesText extends React.Component<ModBlockTwoImagesTextProps, {}> {
  render() {
    const {content, pathname, pageColor} = this.props

    if (!content._i18nCL) {
      return null
    }
    const i18n = content._i18nCL

    let text = null
    if (i18n.text) {
      text = <ModRichText richText={i18n.text} pathname={pathname} />
    }

    return (
      <div className={'block-two-images-wrapper bg_' + pageColor}>
        <div className="block-two-images-text">
          <div className="block-two-images-text-images">
            <ModImgTag imgObject={content.imageLeft} width={374} height={255} />
            <ModImgTag imgObject={content.imageRight} width={374} height={255} />
          </div>
          <div className="l-centered-content">{text}</div>
        </div>
      </div>
    )
  }
}
