import * as React from 'react'
import * as docCookies from 'mozilla-doc-cookies'

export function draftJsHasContent(obj) {
  return obj && obj.blocks && obj.blocks.length > 0 && obj.blocks[0].text
}

export function isInt(data) {
  return data === parseInt(data, 10)
}

export function isEmptyObject(data) {
  return JSON.stringify(data) === '{}'
}

export function leftPad(str, len, ch) {
  str = String(str)
  let i = -1
  if (!ch && ch !== 0) ch = ' '
  len = len - str.length
  while (++i < len) {
    str = ch + str
  }
  return str
}

/**
 * magically pulls the correct lang objects out of i18n maps and stores them in __i18n_current_lang___ in the same struct.
 * @param obj
 * @param currentLanguage
 */
export function addCurrentLangRecursive(obj, currentLanguage) {
  if (!(typeof obj === 'object' && currentLanguage)) {
    return
  }
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let item = obj[key]
      if (key === 'i18n') {
        obj['_i18nCL'] = item.hasOwnProperty(currentLanguage) ? item[currentLanguage] : null
      }
      if (item instanceof Array) {
        addCurrentLangRecursive(item, currentLanguage)
      }
      if (typeof item === 'object') {
        addCurrentLangRecursive(item, currentLanguage)
      }
    }
  }
}

/**
 * Checks if the initial request was https that a client used to connect to a proxy or load balancer
 * @param req
 * @returns {boolean}
 */
export function isHttps(req) {
  return req.headers['x-forwarded-proto'] === 'https'
}

export function getJsonCookie(cookieName) {
  if (typeof window !== 'undefined') {
    const object = docCookies.getItem(cookieName)
    if (object) {
      return JSON.parse(object)
    }
  }
  return null
}

export function setJsonCookie(cookieName, json) {
  if (json && typeof window !== 'undefined') {
    docCookies.setItem(cookieName, JSON.stringify(json), Infinity, '/')
  }
}

export function makeDeepCopy(obj) {
  if (!obj) {
    return null
  }
  return JSON.parse(JSON.stringify(obj))
}

/**
 * Checks if an array or string has content
 * @param property {array|string}
 * @returns {boolean}
 */
export function hasContent(property) {
  if (typeof property === 'string') {
    return property !== ''
  }

  if (property instanceof Array) {
    return property.length > 0
  }

  return false
}

/**
 *
 * @param element
 * @param className
 */
export function addClassToElement(element, className) {
  if (element.classList) element.classList.add(className)
  else element.className += ' ' + className
}

/**
 *
 * @param element
 * @param className
 */
export function removeClassFromElement(element, className) {
  if (element.classList) element.classList.remove(className)
  else
    element.className = element.className.replace(
      new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
      ' '
    )
}

/**
 * removes slashes at the beginning and end of a string
 * @param value
 * @returns {string}
 */
export function stripSlashes(value) {
  if (typeof value !== 'string') return value
  return value.replace(/^\//, '').replace(/\/$/, '')
}

export function shuffleArray(array) {
  let counter = array.length

  while (counter > 0) {
    let index = Math.floor(Math.random() * counter)
    counter--
    let temp = array[counter]
    array[counter] = array[index]
    array[index] = temp
  }

  return array
}
