require('intersection-observer')
require('static/scss/index.scss')

import React from 'react'
import {render} from 'react-dom'
import {Router, browserHistory} from 'react-router'
import Routes from 'components/Routes'

browserHistory.listen(function (location) {
  // Google Analytics basic implementation
  if (typeof ga !== 'undefined') {
    ga('set', 'page', location.pathname)
    ga('send', 'pageview')
  }

  if (typeof dataLayer !== 'undefined') {
    dataLayer.push({
      event: 'pageView',
      pageURL: location.pathname,
    })
  }
})

const router = <Router routes={Routes} history={browserHistory} />

if (process.env.NODE_ENV === 'development') {
  const {hot} = require('react-hot-loader')
  const App = () => router
  const HotReloadApp = hot(module)(App)
  render(<HotReloadApp />, document.getElementById('app-wrapper'))
} else {
  render(router, document.getElementById('app-wrapper'))
}
