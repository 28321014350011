const isoFetch = require('isomorphic-fetch')

export default class CaasApi {
  endpoint: string
  database: string
  user: string
  pwd: string
  signature: string
  tags: string

  constructor(endpoint: string, database: string, user: string, pwd: string) {
    this.endpoint = endpoint
    this.database = database
    this.user = user
    this.pwd = pwd
    this.signature = null
    this.tags = null

    this.hasSession = this.hasSession.bind(this)
    this.signOut = this.signOut.bind(this)
    this.signIn = this.signIn.bind(this)
    this.getSignature = this.getSignature.bind(this)
    this.setSignature = this.setSignature.bind(this)
    this.query = this.query.bind(this)
    this.fetchTags = this.fetchTags.bind(this)
    this.getContentIdByTag = this.getContentIdByTag.bind(this)
  }

  hasSession() {
    return hasContent(this.signature)
  }

  getSignature() {
    return this.signature
  }

  setSignature(signature) {
    this.signature = signature
  }

  signOut() {
    this.signature = null
  }

  async fetchTags() {
    if (!this.hasSession()) {
      return null
    }
    let result = await this.query({
      mapList: {
        value: {
          all: {
            tag: '_tag',
          },
        },
        expression: {
          metarialize: {
            id: {},
          },
        },
      },
    })

    this.tags = result.reduce((prevItem, item) => {
      prevItem[item.value.tag] = item.value.model
      return prevItem
    }, {})
    return this.tags
  }

  getContentIdByTag(tag) {
    return this.tags[tag]
  }

  async signIn() {
    const url = this.endpoint + '/auth'
    const headers = new Headers()

    if (!hasContent(this.user)) {
      throw new CaasApiException('no user provided')
    }

    if (!hasContent(this.pwd)) {
      throw new CaasApiException('no pwd provided')
    }

    headers.append('X-Karma-Database', this.database)
    headers.append('X-Karma-Codec', 'json')

    let options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        username: this.user,
        password: this.pwd,
      }),
    }

    const response = await isoFetch(url, options)
    if (response.status !== 200) {
      throw new CaasApiException(response.status + ' login was not successful')
    }
    this.signature = await response.json()
    await this.fetchTags()
    return this.signature
  }

  /**
   * executes a karma query.
   * 1. checks if there is a signature
   * 2. tries to sign in if there is no signature provided
   * 3. tries to execute query
   * 4. tries to sign in if the signature is expired (403) and recalls query
   * @param query
   * @param retry after 403
   * @returns {Promise}
   */
  async query(query: any, retry = true) {
    if (!this.hasSession()) {
      await this.signIn()
    }

    if (!hasContent(this.signature)) {
      throw new CaasApiException('no signature provided')
    }

    if (!hasContent(this.database)) {
      throw new CaasApiException('no database provided')
    }

    const headers = new Headers()
    headers.append('X-Karma-Database', this.database)
    headers.append('X-Karma-Codec', 'json')
    headers.append('X-Karma-Signature', this.signature)

    let options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(query),
    }
    const response = await isoFetch(this.endpoint, options)
    const json = await response.json()

    if (response.status === 403 && retry) {
      // session is expired. try to sign in
      await this.signIn()
      return await this.query(query, false)
    }
    if (response.status === 200) {
      return json
    } else {
      throw new CaasApiException('response fault', query, response.headers, {
        status: response.status,
        statusText: response.statusText,
        body: json,
      })
    }
  }
}

function CaasApiException(message: string, query = null, responseHeaders = null, body = null) {
  this.responseHeaders = responseHeaders
  this.body = body
  this.query = query
  this.message = message
  this.name = 'CaasApiException'
}

CaasApiException.prototype.toString = function () {
  let query = ''
  if (this.query) {
    query += 'QUERY:\n'
    query += JSON.stringify(this.query) + '\n'
  }
  let headers = ''
  if (this.responseHeaders) {
    headers += 'HEADERS:\n'
    try {
      for (let pair of this.responseHeaders.entries()) {
        headers += pair[0] + ': ' + pair[1] + '\n'
      }
    } catch (e) {}
  }
  const body = this.body ? 'BODY:\n' + JSON.stringify(this.body) : ''
  return this.name + ': ' + this.message + '\n' + query + headers + body
}

function hasContent(property) {
  return property && property !== ''
}
