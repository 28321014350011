import * as React from 'react'

const lazyLoading = false

interface ModImgTagProps {
  imgObject: {
    cloudinaryMedia: {
      url: string
    }
    _i18nCL?: any
  }
  width?: number
  height?: number
  transformation?: string
  className?: string
  swiperLazy?: boolean
  lazyComponent?: any
  pathname?: any
}

interface ModImageTagState {
  lazyComponent?: any
  visible?: boolean
}

export default class ModImgTag extends React.Component<ModImgTagProps, ModImageTagState> {
  private lazyComponent: any
  private observer: IntersectionObserver

  static addCloudinaryParamsToUrl(url, cloudinaryParams) {
    url = url.replace(
      /^https:\/\/media\.marche-movenpick\.com/,
      'https://media.marche-restaurants.com'
    )

    return url.replace(/(.+upload\/)(.+)/, `$1${cloudinaryParams.join(',')}/$2`)
  }

  constructor(props) {
    super(props)

    this.lazyComponent = null
    this.state = {visible: false}

    this.startObserve = this.startObserve.bind(this)
    this.stopObserve = this.stopObserve.bind(this)
    this.callBack = this.callBack.bind(this)

    if (
      typeof window !== 'undefined' &&
      typeof IntersectionObserver !== 'undefined' &&
      lazyLoading
    ) {
      this.observer = new IntersectionObserver(this.callBack, {
        rootMargin: '300px',
        threshold: 0.1,
      })
    }
  }

  componentDidMount() {
    this.startObserve()
  }

  componentDidUpdate() {
    this.startObserve()
  }

  componentWillUnmount() {
    this.stopObserve()
  }

  startObserve() {
    this.stopObserve()
    if (this.observer && this.lazyComponent) {
      this.observer.observe(this.lazyComponent)
    }
  }

  stopObserve() {
    if (this.observer && this.lazyComponent) {
      this.observer.unobserve(this.lazyComponent)
    }
  }

  callBack(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.setState({visible: true})
      }
    })
  }

  render() {
    const {imgObject} = this.props

    if (!imgObject) {
      console.error('ModImgTag: no image object provided')
      return <img src={require('static/img/broken-image.png')} />
    }

    if (typeof imgObject === 'string') {
      console.error('ModImgTag: image id instead of image object provided', imgObject)
      return <img src={require('static/img/broken-image.png')} />
    }

    let cloudinaryParams = ['q_60']
    let cloudinaryParams2X = ['q_60']
    let addTransformation = false
    if (this.props.width) {
      addTransformation = true
      cloudinaryParams.push('w_' + this.props.width)
      cloudinaryParams2X.push('w_' + this.props.width * 2)
    }
    if (this.props.height) {
      addTransformation = true
      cloudinaryParams.push('h_' + this.props.height)
      cloudinaryParams2X.push('h_' + this.props.height * 2)
    }

    if (addTransformation) {
      cloudinaryParams.push(this.props.transformation || 'c_fill')
      cloudinaryParams2X.push(this.props.transformation || 'c_fill')
    }

    // use default media
    let url = ModImgTag.addCloudinaryParamsToUrl(imgObject.cloudinaryMedia.url, cloudinaryParams)
    let url2X = ModImgTag.addCloudinaryParamsToUrl(
      imgObject.cloudinaryMedia.url,
      cloudinaryParams2X
    )
    let altText = ''
    let title = ''
    if (imgObject._i18nCL) {
      altText = imgObject._i18nCL.altText
      title = imgObject._i18nCL.title
      if (imgObject._i18nCL.cloudinaryMedia) {
        // use i18 media if present
        url = ModImgTag.addCloudinaryParamsToUrl(
          imgObject._i18nCL.cloudinaryMedia.url,
          cloudinaryParams
        )
        url2X = ModImgTag.addCloudinaryParamsToUrl(
          imgObject._i18nCL.cloudinaryMedia.url,
          cloudinaryParams2X
        )
      }
    }

    url2X = encodeURI(url2X)
    const className = this.props.className ? this.props.className : ''

    if (this.props.swiperLazy) {
      return (
        <img
          data-src={url}
          data-srcset={url2X + ' 2x'}
          className={'swiper-lazy ' + className}
          title={title}
          alt={altText}
        />
      )
    }

    if (!this.state.visible && lazyLoading) {
      return (
        <img
          className={className}
          title={title}
          alt=""
          ref={(input) => {
            this.lazyComponent = input
          }}
        />
      )
    }

    return (
      <img src={url} srcSet={url2X + ' 2x'} title={title} alt={altText} className={className} />
    )
  }
}
