import * as React from 'react'

export interface ModBlockTitleProps {
  content: {
    _i18nCL: {
      title: string
      subtitle: string
      anchor: string
    }
  }
  pathname: string
  pageColor: string
}

export default class ModBlockTitle extends React.Component<ModBlockTitleProps> {
  render() {
    const {content, pageColor} = this.props

    if (!(content._i18nCL && content._i18nCL.title)) {
      return null
    }
    const i18n = content._i18nCL

    let subTitle = i18n.subtitle ? (
      <h2 className="block-title-subtitle typo-h2">{i18n.subtitle}</h2>
    ) : null

    const a = i18n.anchor ? (
      <a className="anchor" id={i18n.anchor.toLowerCase()} href={i18n.anchor.toLowerCase()} />
    ) : null

    return (
      <div className={'block-title bg_' + pageColor}>
        <div className="block-title-content" />
        {a}
        <div className="block-title-triangle">
          <h1 className="typo-h1">{i18n.title}</h1>
          {subTitle}
        </div>
      </div>
    )
  }
}
