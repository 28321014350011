import * as React from 'react'
import * as svg4everybody from 'svg4everybody'
import Swiper from 'swiper'
import ModSvg from '../../components/common/ModSvg'

export interface ModIdangerousSwiperProps {
  id: string
  hasNavigation: boolean
  hasPagination: boolean
  maxPaginationCount: number
  hasSlideNumber: boolean
  children: any[]
  navigationIcon?: string
  hasFullScreen: boolean
  fullScreenIcon?: string
  options?: any
  onSlide?: (activity: any, activeIndex: number) => void
  hasHomeNavigation?: any
  textSlideshowBlock?: any
  autoplay?: any
  numberOfPictures?: number
  sliderHome?: string
  sliderHomeHover?: string
  buttonInsideText?: boolean
}

export interface ModIdangerousSwiperState {
  activeSlide: number
}

interface Params {
  spaceBetween: number
  lazy: {
    loadPrevNext: boolean
  }
  navigation: any
  pagination: any
  speed: number
  effect: string
  simulateTouch: boolean
  loop: boolean
  autoplay: any
  slidesPerView: string
}

export default class ModIdangerousSwiper extends React.Component<
  ModIdangerousSwiperProps,
  ModIdangerousSwiperState
> {
  swiper: any

  constructor(props) {
    super(props)

    this.state = {
      activeSlide: 1,
    }

    this.swiper = null
    this.destroySwiperComponents = this.destroySwiperComponents.bind(this)
    this.createSwiperComponents = this.createSwiperComponents.bind(this)
    this.onSliderSlide = this.onSliderSlide.bind(this)
  }

  componentWillUnmount() {
    this.destroySwiperComponents()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.children.length !== prevProps.children.length || !this.swiper) {
      this.createSwiperComponents()
    }
  }

  componentDidMount() {
    this.createSwiperComponents()

    if (typeof window !== 'undefined') {
      svg4everybody({
        polyfill: true,
      })
    }
  }

  createSwiperComponents() {
    this.destroySwiperComponents()

    let params: Params = {
      spaceBetween: 10,
      lazy: {
        loadPrevNext: true,
      },
      navigation: {},
      pagination: {},
      speed: 600,
      effect: 'slide',
      simulateTouch: false,
      loop: false,
      autoplay: false,
      slidesPerView: 'auto',
    }

    if (this.props.options) {
      params = Object.assign(params, this.props.options)
    }
    if (this.props.hasNavigation) {
      params.navigation = {
        nextEl: '#' + this.props.id + ' .swiper-button-next',
        prevEl: '#' + this.props.id + ' .swiper-button-prev',
      }
    }
    if (this.props.hasPagination) {
      params.pagination = {
        el: '#' + this.props.id + ' .swiper-pagination',
        clickable: true,
      }
    }
    if (this.props.autoplay) {
      params.speed = 600
      params.effect = 'slide'
      params.spaceBetween = 0
      params.simulateTouch = false
      params.autoplay = {
        disableOnInteraction: true,
        delay: 3000,
      }
      if (this.props.numberOfPictures > 1) {
        params.loop = true
      }
    }

    this.swiper = new Swiper('#' + this.props.id, params)

    this.swiper.on('slideChangeTransitionStart', () => {
      this.onSliderSlide(true)
    })
    this.swiper.on('sliderMove', () => {
      this.onSliderSlide(true)
    })
    this.swiper.on('slideChangeTransitionEnd', () => {
      this.onSliderSlide(false)
    })
  }

  onSliderSlide(activity) {
    if (this.state.activeSlide !== this.swiper.activeIndex + 1 && !activity) {
      this.setState({
        activeSlide: this.swiper.activeIndex + 1,
      })
    }
    if (this.props.onSlide) {
      this.props.onSlide(activity, this.swiper.activeIndex)
    }
  }

  destroySwiperComponents() {
    if (this.swiper) {
      this.swiper.off('onSlideChangeStart')
      this.swiper.off('onSliderMove')
      this.swiper.off('onSlideChangeEnd')
      this.swiper.destroy()
    }
  }

  addLeadingZeros(number, maxNumber) {
    let digit_count = maxNumber.toString().length
    if (digit_count < 2) digit_count = 2

    let s = number.toString()
    while (s.length < digit_count) {
      s = '0' + s
    }
    return s
  }

  render() {
    const {
      children,
      hasNavigation,
      buttonInsideText,
      hasHomeNavigation,
      hasFullScreen,
      hasPagination,
      maxPaginationCount,
      hasSlideNumber,
      id,
      textSlideshowBlock,
      sliderHome,
      sliderHomeHover,
    } = this.props

    let swiperButton = null

    if (hasNavigation) {
      const naName = textSlideshowBlock ? 'text-swiper-buttons' : 'swiper-button-wrapper'
      const icons = hasHomeNavigation
        ? require('static/img/svg/but_arrow_home.svg')
        : require('static/img/svg/but_arrow_slider.svg')

      if (children.length > 1) {
        if (sliderHome) {
          let icon = require('static/img/svg/' + sliderHome)
          let iconHover = require('static/img/svg/' + sliderHomeHover)

          swiperButton = (
            <div className={naName}>
              <div className="swiper-button-next">
                <ModSvg className="SVG" url={icon} id="icon" />
                <ModSvg className="hoverSVG" url={iconHover} id="icon" />
              </div>
              <div className="swiper-button-prev">
                <ModSvg className="SVG" url={icon} id="icon" />
                <ModSvg className="hoverSVG" url={iconHover} id="icon" />
              </div>
            </div>
          )
        } else {
          swiperButton = (
            <div className={naName}>
              <div className="swiper-button-next">
                <ModSvg url={icons} id="icon" />
              </div>
              <div className="swiper-button-prev">
                <ModSvg url={icons} id="icon" />
              </div>
            </div>
          )
        }
      }
    }

    return (
      <div className="swiper-container" id={id}>
        <div className="swiper-wrapper">{children}</div>
        {hasFullScreen ? (
          <div className="swiper-button-fullscreen">
            <ModSvg url={require('static/img/svg/but_double_arrow.svg')} id="icon" />
          </div>
        ) : (
          ''
        )}
        {swiperButton}
        {hasPagination && children.length < maxPaginationCount ? (
          <div className="swiper-pagination" />
        ) : (
          ''
        )}
        {hasSlideNumber ? (
          <div className="swiper-slide-number">
            {this.addLeadingZeros(this.state.activeSlide, children.length)}/
            {this.addLeadingZeros(children.length, children.length)}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}
