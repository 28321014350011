import * as React from 'react'
import ModBlockTitle from '../../components/blocks/ModBlockTitle'
import ModBlockSpacer from '../../components/blocks/ModBlockSpacer'
import ModBlockTeaser from '../../components/blocks/ModBlockTeaser'
import ModBlockRichText from '../../components/blocks/ModBlockRichText'
import ModBlockTeaserSpot from '../../components/blocks/ModBlockTeaserSpot'
import ModBlockSlider from '../blocks/ModBlockSlider'
import ModBlockInstagramLightWidget from '../../components/blocks/ModBlockInstagramLightWidget'
import ModBlockVimeo from '../../components/blocks/ModBlockVimeo'
import ModBlockImage from '../blocks/ModBlockImage'
import ModEditBlock from '../../components/common/ModEditBlock'
import ModBlockFallow from '../../components/blocks/ModBlockFollow'
import ModBlockLocations from '../../components/blocks/ModBlockLocations'
import {injectIntl} from 'react-intl'
import ModBlockHome from '../blocks/ModBlockHome'
import ModBlockGridThreeColumns from '../blocks/ModBlockGridThreeColumns'
import ModBlockTwoImagesText from '../blocks/ModBlockTwoImagesText'
import ModBlockTestimonials from '../blocks/ModBlockTestimonials'

interface ModTemplateBlockListProps {
  templateData: {
    contents: any
  }
  config: {
    editMode: any
  }
  currentLanguage: string
  pathname: string
  caasHelper: any
  navigationNodeId: string
  navigationTree: string
  pageColor: string
}

class ModTemplateBlockList extends React.Component<ModTemplateBlockListProps, {}> {
  render() {
    const {
      templateData,
      config,
      currentLanguage,
      pathname,
      caasHelper,
      navigationNodeId,
      navigationTree,
      pageColor,
    } = this.props

    const blockList = templateData.contents.map((_item, index) => {
      const typeKey = Object.keys(_item)[0]
      const content = _item[typeKey]
      const {block} = content
      const {style} = content
      return {typeKey, block, content, style}
    })

    let html = blockList.reduce((itemList, item, index) => {
      const {typeKey, block, content, style} = item

      let blockMarkup = null
      switch (typeKey) {
        case 'blockCorporateHome':
          blockMarkup = <ModBlockHome key={index} pathname={pathname} content={block} />
          break
        case 'blockTitle':
          blockMarkup = (
            <ModBlockTitle key={index} content={block} pathname={pathname} pageColor={pageColor} />
          )
          break
        case 'blockRichText':
          blockMarkup = (
            <ModBlockRichText key={index} content={block} pathname={pathname} style={style} />
          )
          break
        case 'blockSpacer':
          blockMarkup = <ModBlockSpacer key={index} content={block} />
          break
        case 'blockTeaser':
          blockMarkup = (
            <ModBlockTeaser
              key={index}
              content={block}
              pathname={pathname}
              currentLanguage={currentLanguage}
              pageColor={pageColor}
            />
          )
          break
        case 'blockSlider':
          if (block.list.length === 1) {
            // If there is just one item in the list, display an simple Image Block
            blockMarkup = (
              <ModBlockImage key={index} content={{image: block.list[0]}} pathname={pathname} />
            )
          } else {
            blockMarkup = <ModBlockSlider key={index} content={block} index={index} />
          }
          break
        case 'blockLocations':
          blockMarkup = (
            <ModBlockLocations
              key={index}
              content={block}
              voConfig={config}
              currentLanguage={currentLanguage}
              pathname={pathname}
            />
          )
          break
        case 'blockTestimonials':
          blockMarkup = <ModBlockTestimonials key={index} content={block} pathname={pathname} />
          break
        case 'blockTeaserSpot':
          blockMarkup = <ModBlockTeaserSpot key={index} content={block} pathname={pathname} />
          break
        case 'blockInstagramLightWidget':
          blockMarkup = <ModBlockInstagramLightWidget key={index} content={block} />
          break
        case 'blockVimeo':
          blockMarkup = <ModBlockVimeo key={index} content={block} size={content.size} />
          break
        case 'blockFollow':
          blockMarkup = <ModBlockFallow key={index} content={block} pageColor={pageColor} />
          break
        case 'blockGridThreeColumns':
          blockMarkup = (
            <ModBlockGridThreeColumns
              key={index}
              content={block}
              currentLanguage={currentLanguage}
              pathname={pathname}
            />
          )
          break
        case 'blockTwoImagesText':
          blockMarkup = (
            <ModBlockTwoImagesText
              key={index}
              content={block}
              pathname={pathname}
              pageColor={pageColor}
            />
          )
          break
        default:
          blockMarkup = null
      }

      if (config.editMode && blockMarkup) {
        itemList.push(
          <ModEditBlock key={'_' + index} entityObject={block} title={typeKey} config={config} />
        )
      }
      itemList.push(blockMarkup)
      return itemList
    }, [])

    return <div className="layout-article">{html}</div>
  }
}

export default injectIntl(ModTemplateBlockList)
