import * as React from 'react'
import ModRichText from '../common/ModRichText'
import {draftJsHasContent} from '../../common/DDUtil'

interface ModBlockRichTextProps {
  content: {
    i18n: {
      [key: string]: {
        text: any
      }
    }
    _i18nCL: {
      text: any
    }
  }
  pathname: string
  style: any
}

export default class ModBlockRichText extends React.Component<ModBlockRichTextProps> {
  render() {
    const {content, pathname, style} = this.props

    if (!(content._i18nCL && draftJsHasContent(content._i18nCL.text))) {
      return null
    }

    const i18n = content._i18nCL

    return (
      <div className="block-rich-text">
        <div
          className={
            style == 'default-font'
              ? 'l-centered-content typo-h4 default-font'
              : 'l-centered-content typo-h3'
          }
        >
          <ModRichText richText={i18n.text} pathname={pathname} />
        </div>
      </div>
    )
  }
}
