import * as React from 'react'
import * as svg4everybody from 'svg4everybody'
import ModAnchorTag from '../../components/common/ModAnchorTag'
import ModRichText from '../../components/common/ModRichText'
import ModSvg from '../common/ModSvg'
import {FormattedMessage} from 'react-intl'

declare var Cookiebot: any

interface ModBlockGridThreeColumnsPorps {
  content: {
    list: any
  }
  pathname: string
  currentLanguage: string
}

export default class ModBlockGridThreeColumns extends React.Component<
  ModBlockGridThreeColumnsPorps,
  {activeCell}
> {
  element: HTMLDivElement[]

  constructor(props) {
    super(props)

    this.state = {
      activeCell: null,
    }

    this.toggleContent = this.toggleContent.bind(this)
    this.closeContent = this.closeContent.bind(this)
  }

  setStateBy(replaceObjects) {
    this.setState(Object.assign({}, this.state, replaceObjects))
  }

  toggleContent(e) {
    const cellImg = e.currentTarget
    if (cellImg) {
      let id = cellImg.dataset.id
      if (id === this.state.activeCell) {
        id = null
      }
      this.setStateBy({
        activeCell: id,
      })
    }
  }

  closeContent(e) {
    this.setStateBy({
      activeCell: null,
    })
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      svg4everybody({
        polyfill: true,
      })
    }

    Cookiebot.runScripts()
  }

  render() {
    const {content, pathname, currentLanguage} = this.props

    const list = content.list.map((item, index) => {
      const content = item.content

      let markup = null
      let contentList = []
      let contentLarge = null

      if (content.hasOwnProperty('textColumn')) {
        let textData = content.textColumn

        if (!textData._i18nCL) {
          return null
        }
        const i18n = textData._i18nCL

        let openingHours = i18n.openingHours.map((item, index) => {
          return (
            <div key={'openingHours_' + index} className="opening-hours">
              <div className="days">{item.days}</div>

              <div className="hours">{item.hours}</div>
            </div>
          )
        })

        let textMarkup = (
          <div key={index} className="block-grid-three-cell-text-wrapper">
            <div className="opening-hours-wrapper">{openingHours}</div>

            <div className="block-grid-three-cell-content">
              <ModRichText richText={i18n.text} pathname={pathname} />
            </div>
          </div>
        )

        if (textData.link) {
          textMarkup = (
            <ModAnchorTag key={index} linkObject={textData.link} pathname={pathname}>
              {textMarkup}
            </ModAnchorTag>
          )
        }
        contentList.push(textMarkup)
      } else if (content.hasOwnProperty('linkList')) {
        let linkList = content.linkList.map((item, index) => {
          if (!item._i18nCL) {
            return null
          }
          const i18n = item._i18nCL

          return (
            <ModAnchorTag key={index} linkObject={i18n.link} pathname={pathname}>
              <div className="block-grid-three-cell-link-item">
                {i18n.text}
                <ModSvg url={require('static/img/svg/but_menu_lo.svg')} id="icon" />
              </div>
            </ModAnchorTag>
          )
        })

        contentList.push(
          <div key={index} className="block-grid-three-cell-link-wrapper">
            {linkList}
          </div>
        )
      } else if (content.hasOwnProperty('widgetBookatable')) {
        let url =
          `https://module.lafourchette.com/${
            currentLanguage === 'de' ? 'de_DE' : 'en_US'
          }/module/` + content.widgetBookatable.widgetBookatable.connectionId
        let className = 'bookatable'

        if (content.widgetBookatable.widgetBookatable.widgetType === 'resmio') {
          className = 'resmio'
          url = `/api/widgets/resmio.html?locationId=${content.widgetBookatable.widgetBookatable.connectionId}`
        }

        let trip = null
        if (content.widgetBookatable.widgetTripadvisor) {
          let url_trip = `/api/widgets/tripadvisor.html?locationId=${content.widgetBookatable.widgetTripadvisor.locationId}&language=${currentLanguage}`

          trip = (
            <div className="iframewrapper" key={index}>
              <div className="tripadvisor-wrapper">
                <div className="cookieconsent-optout-marketing">
                  <div className="cookieconsent-optout-marketing-custom">
                    <h3>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault()
                          Cookiebot.renew()
                        }}
                      >
                        <FormattedMessage
                          id="cookiePolicy.acceptMarketing"
                          defaultMessage="Please accept marketing cookies to see this content."
                        />
                      </a>
                    </h3>
                  </div>
                </div>
                <iframe
                  className="tripadvisor"
                  data-cookieblock-src={url_trip}
                  data-cookieconsent="marketing"
                />
              </div>
            </div>
          )
        }

        contentLarge = (
          <div className="iframewrapper">
            <div className="clipbookatable">
              <div className="cookieconsent-optout-marketing">
                <div className="cookieconsent-optout-marketing-custom">
                  <h3>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        Cookiebot.renew()
                      }}
                    >
                      <FormattedMessage
                        id="cookiePolicy.acceptMarketing"
                        defaultMessage="Please accept marketing cookies to see this content."
                      />
                    </a>
                  </h3>
                </div>
              </div>
              <iframe
                className={className}
                data-cookieblock-src={url}
                data-cookieconsent="marketing"
              />

              <div id="resmio-palavrion-hannover-airport"></div>
            </div>
          </div>
        )
        contentList.push(
          <div key={index} className="bookatable-wrapper">
            <div className="bookatable-text">{content.widgetBookatable._i18nCL.text}</div>
            {trip}
          </div>
        )
      }

      return (
        <div key={index} className="block-grid-three-cell">
          <div className="content">
            <div className="content-padding">
              <div className="content-title">{item._i18nCL.title}</div>
              {contentList}
            </div>
            {contentLarge}
          </div>
        </div>
      )
    })

    return <div className="block-grid-three-column">{list}</div>
  }
}
