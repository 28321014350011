import * as React from 'react'
import ModImgTag from '../../components/common/ModImgTag'

interface ModBlockImageProps {
  content: any
  pathname: string
  key: any
}

export default class ModBlockImage extends React.Component<ModBlockImageProps, {}> {
  render() {
    const {content} = this.props

    if (!content.image._i18nCL) {
      return null
    }
    const i18n = content.image._i18nCL

    let caption = null
    if (i18n.title !== '' || i18n.text !== '') {
      caption = (
        <div className="caption">
          <h4 className="type-h4 l-centered-content">{i18n.title}</h4>
          <p className="typo-h6 l-centered-content">{i18n.text}</p>
        </div>
      )
    }

    return (
      <div className="block-image-wrapper">
        <div className="block-image-content">
          <ModImgTag imgObject={content.image.image} />
          {caption}
        </div>
      </div>
    )
  }
}
