import * as React from 'react'
import ModTemplateBlockList from './templates/ModTemplateBlockList'
import Mod404 from './Mod404'
import ModEditBlock from '../components/common/ModEditBlock'

interface ModContentProps {
  config: any
  content: {
    navigationNodeId: any
    article: {
      i18n: any
      websiteColor: string
      content: {
        templateBlockListPalavrion: any
      }
    }
  }
  caasHelper: any
  navigationTree: any
  currentLanguage: any
  pathname: string
}

export default class ModContent extends React.Component<ModContentProps> {
  render() {
    const {content, config, caasHelper, navigationTree, currentLanguage, pathname} = this.props

    let contentHtml = <Mod404 />
    let editMode = null

    if (content && content.article) {
      const title = content.article.i18n.hasOwnProperty(currentLanguage)
        ? content.article.i18n[currentLanguage].title
        : 'Site'
      if (config.editMode) {
        editMode = <ModEditBlock entityObject={content.article} title={title} config={config} />
      }
      if (content.article.content.hasOwnProperty('templateBlockListPalavrion')) {
        contentHtml = (
          <ModTemplateBlockList
            templateData={content.article.content.templateBlockListPalavrion}
            config={config}
            currentLanguage={currentLanguage}
            pathname={pathname}
            caasHelper={caasHelper}
            navigationNodeId={content.navigationNodeId}
            navigationTree={navigationTree}
            pageColor={content.article.websiteColor}
          />
        )
      }
    }

    return (
      <div id="content">
        {editMode}
        {contentHtml}
      </div>
    )
  }
}
