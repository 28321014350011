import * as React from 'react'

interface ModEditBlockProps {
  entityObject: any
  title: string
  config: any
}

export default class ModEditBlock extends React.Component<ModEditBlockProps> {
  render() {
    const {entityObject, title, config} = this.props

    return (
      <div className="edit-mode">
        <div className="edit-mode-img">
          <img className="" src={require('static/img/karmaLogo.png')} />
        </div>

        <div className="edit-mode-text">
          <p className="typo-h6">{title}</p>
          <a
            className="typo-h7"
            href={
              config.publicConfig.caasEditorUrl +
              '/entries/' +
              entityObject._type +
              '/edit/' +
              entityObject._id
            }
            target="_blank"
            rel="noopener"
          >
            EDIT
          </a>
        </div>
      </div>
    )
  }
}
