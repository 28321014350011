import * as React from 'react'
import * as svg4everybody from 'svg4everybody'
import {tags} from '../vo/VoConfig'
import {addCurrentLangRecursive, removeClassFromElement, addClassToElement} from '../common/DDUtil'
import ModBlockTwoImagesText from './blocks/ModBlockTwoImagesText'
import ModSvg from './common/ModSvg'

interface ModModalViewProps {
  onClose: any
  caasHelper: any
  location: any
  navigationTree: any
  currentLanguage: string
  pathname: string
}

interface ModModalViewState {
  overlayContent: any
}

export default class ModModalView extends React.Component<ModModalViewProps, ModModalViewState> {
  constructor(props) {
    super(props)

    this.state = {
      overlayContent: null,
    }
    this.onClose = this.onClose.bind(this)
    this.setStateBy = this.setStateBy.bind(this)
    this.fetchOverlayContentById = this.fetchOverlayContentById.bind(this)
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      svg4everybody({
        polyfill: true,
      })
    }
    this.fetchOverlayContentById(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.fetchOverlayContentById(nextProps)
  }

  setStateBy(replaceObjects) {
    this.setState(Object.assign({}, this.state, replaceObjects))
  }

  fetchOverlayContentById(props) {
    const {caasHelper, navigationTree, location, currentLanguage} = props

    if (location.query && location.query.overlay) {
      caasHelper
        .fetchOverlay(navigationTree, location.query.overlay)
        .then((result) => {
          addCurrentLangRecursive(result, currentLanguage)
          this.setStateBy({
            overlayContent: result,
          })
        })
        .catch((error) => {
          this.setStateBy({
            overlayContent: null,
          })
        })
    } else {
      this.setStateBy({
        overlayContent: null,
      })
    }
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    const {location, currentLanguage, pathname} = this.props

    let className = 'hidden'
    let content = null
    if (this.state.overlayContent) {
      className = 'visible'
      const typeKey = Object.keys(this.state.overlayContent.content)[0]
      const blockData = this.state.overlayContent.content[typeKey]

      switch (typeKey) {
        case tags.overlayTwoImagesText:
          content = <ModBlockTwoImagesText content={blockData} />
          break
        case tags.blockTextSlideShow:
          console.log('ModModalView: blockTextSlideShow is not implemented for palavrion')
          break
        case tags.blockVimeo:
          console.log('ModModalView: blockVimeo is not implemented for palavrion')
          break
        case tags.blockSnippet:
          console.log('ModModalView: blockSnippet is not implemented for palavrion')
          break
      }
    } else if (location.query && location.query.overlayVimeoId) {
      className = 'visible'
      console.log('ModModalView: blockVimeo is not implemented for palavrion')
    }

    if (typeof window !== 'undefined') {
      let body = document.getElementsByTagName('BODY')[0]
      if (className === 'hidden') {
        removeClassFromElement(body, 'overflow-hidden')
      } else {
        addClassToElement(body, 'overflow-hidden')
      }
    }

    return (
      <div id="modal" className={className}>
        <div className="modal-close-button-wrapper">
          <div
            className={className === 'visible' ? 'modal-button' : 'modal-button hidden'}
            onClick={this.onClose}
          >
            <ModSvg url={require('static/img/svg/but_close.svg')} id="icon" />
          </div>
        </div>
        <div className="modal-content">{content}</div>
      </div>
    )
  }
}
