import * as React from 'react'
import ModAnchorTag from '../../components/common/ModAnchorTag'
import ModImgTag from '../../components/common/ModImgTag'
import ModIdangerousSwiper from '../../components/common/ModIdangerousSwiper'

const MAX_IMG_SIZE = 1000

interface ModBlockHomeProps {
  content: {
    list: {
      image: any
      vimeoId: string
      spotImage: any
      spotImageAltText: string
      spotLink: any
      length: number
      map: any
    }
    _id: string
  }
  pathname: string
}

interface ModBlockHomeState {
  activeIndex: number
  lastIndex: number
}

export default class ModBlockHome extends React.Component<ModBlockHomeProps, ModBlockHomeState> {
  blockHome: any

  constructor(props) {
    super(props)

    this.state = {
      activeIndex: 0,
      lastIndex: -1,
    }

    this.onSlide = this.onSlide.bind(this)
  }

  onSlide(activity, activeIndex) {
    if (this.state.activeIndex !== activeIndex && !activity) {
      this.setState({
        lastIndex: this.state.activeIndex,
        activeIndex: activeIndex,
      })
    }
  }

  render() {
    const {content, pathname} = this.props

    if (!(content && content.list.length > 0)) {
      return null
    }

    let spots = []
    let homeSlider = content.list.map((item, index) => {
      let cssAnimation = ''
      let className = index === this.state.activeIndex ? 'spot active' : 'spot'
      if (item.spotLink) {
        spots.push(
          <div key={'spot' + index} className={className}>
            <ModAnchorTag linkObject={item.spotLink} pathname={pathname}>
              <ModImgTag imgObject={item.spotImage} />
            </ModAnchorTag>
          </div>
        )
      } else {
        spots.push(
          <div key={'spot' + index} className={className}>
            <ModImgTag imgObject={item.spotImage} />
          </div>
        )
      }

      return (
        <div key={index} className={'swiper-slide ' + cssAnimation}>
          <div className="spots">{spots}</div>
          <div className="wrapper">
            <ModImgTag
              imgObject={item.image}
              width={MAX_IMG_SIZE}
              height={MAX_IMG_SIZE}
              transformation="c_limit"
            />
          </div>
        </div>
      )
    })

    return (
      <div className="block-home" ref={(ref) => (this.blockHome = ref)}>
        <ModIdangerousSwiper
          id={content._id}
          hasNavigation={true}
          sliderHome={'but_arrow_home_r.svg'}
          sliderHomeHover={'but_arrow_home.svg'}
          hasHomeNavigation={true}
          //  autoplay={10000}
          maxPaginationCount={10}
          hasFullScreen={false}
          onSlide={this.onSlide}
          hasPagination={false}
          hasSlideNumber={false}
          numberOfPictures={homeSlider.length}
        >
          {homeSlider}
        </ModIdangerousSwiper>
      </div>
    )
  }
}
