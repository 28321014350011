import * as React from 'react'
import * as ReactDom from 'react-dom/server'

export interface ModSvgProps {
  url: string
  id: string
  className?: string
}

export default class ModSvg extends React.Component<ModSvgProps, {}> {
  render() {
    const {url, id, className} = this.props

    const href = `${url}#${id}`

    let useElement = ReactDom.renderToStaticMarkup(<use className={className} xlinkHref={href} />)

    return <svg className={className} dangerouslySetInnerHTML={{__html: useElement}}></svg>
  }
}
