import * as React from 'react'
import * as svg4everybody from 'svg4everybody'
import NavLink from '../../components/common/NavLink'
import ModSvg from '../../components/common/ModSvg'
import {getCurrentLanguageOrFallBackByPath} from '../../common/Languages'
import {setDisableGoogleAnalytics} from './HelperFunction'

interface ModAnchorTagProps {
  linkObject: {
    overlay: any
    url: string
    urlMultiLanguage: {
      i18n: any
      _i18nCL: {
        url: string
      }
    }
    navigationNode: {
      i18n: any
      node: {
        i18n: any
      }
      relativeUrl: string
      anchor: {
        blockTitle: any
      }
    }
    document: {
      _i18nCL: any
      cloudinaryMedia: {
        url: string
        type: string
      }
    }
  }
  pathname: string
  hasIcon?: boolean
}

const IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'svg']

export default class ModAnchorTag extends React.Component<ModAnchorTagProps, {}> {
  overlay: any

  static getLinkIconType(linkObject, hasIcon) {
    if (!hasIcon) return null

    let iconType = null
    if (linkObject.hasOwnProperty('url')) {
      if (linkObject.url === '') {
        iconType = <ModSvg url={require('static/img/svg/but_link_doc.svg')} id="icon" />
      }
      if (linkObject.url.startsWith('mailto:')) {
        iconType = <ModSvg url={require('static/img/svg/but_link_filled.svg')} id="icon" />
      } else {
        iconType = <ModSvg url={require('static/img/svg/but_link_external_filled.svg')} id="icon" />
      }
    } else if (linkObject.hasOwnProperty('navigationNode')) {
      iconType = <ModSvg url={require('static/img/svg/but_link_filled.svg')} id="icon" />
    } else if (linkObject.hasOwnProperty('overlay')) {
      iconType = <ModSvg url={require('static/img/svg/but_link_filled.svg')} id="icon" />
    } else if (linkObject.hasOwnProperty('document')) {
      if (linkObject.document.mediaType === 'photo') {
        iconType = <ModSvg url={require('static/img/svg/but_link_img.svg')} id="icon" />
      } else {
        iconType = <ModSvg url={require('static/img/svg/but_link_doc.svg')} id="icon" />
      }
    }
    return iconType
  }

  static invalidateChildren(children) {
    if (typeof children === 'string' || children instanceof Array) {
      return <span>{children}</span>
    } else if (typeof children === 'undefined') {
      return null
    }
    return children
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      svg4everybody({
        polyfill: true,
      })
    }
  }

  render() {
    let children = ModAnchorTag.invalidateChildren(this.props.children)

    if (!(typeof this.props.linkObject === 'object')) {
      return children
    }

    const {linkObject, pathname, hasIcon} = this.props

    try {
      const linkIcon = ModAnchorTag.getLinkIconType(linkObject, hasIcon)

      if (linkObject.hasOwnProperty('url')) {
        if (linkObject.url === '') {
          return children
        }
        if (linkObject.url.startsWith('mailto:')) {
          return (
            <a className={'a-mail'} href={linkObject.url}>
              {linkIcon}
              {children}
            </a>
          )
        }
        if (linkObject.url.startsWith('disableGoogleAnalytics')) {
          return (
            <a href="#!" onClick={setDisableGoogleAnalytics}>
              {children}
            </a>
          )
        }
        const pat = /^\/.*/i
        if (pat.test(linkObject.url)) {
          return (
            <a className={'a-external'} href={linkObject.url} target="_self" rel="noopener">
              {children}
            </a>
          )
        }

        return (
          <a className={'a-external'} href={linkObject.url} target="_blank" rel="noopener">
            {linkIcon}
            {children}
          </a>
        )
      }

      // Url Multi-Language is currently only used in BLock Teaser Spot
      else if (linkObject.hasOwnProperty('urlMultiLanguage')) {
        if (linkObject.urlMultiLanguage._i18nCL.url === '') {
          return children
        }
        if (linkObject.urlMultiLanguage._i18nCL.url.startsWith('mailto:')) {
          return (
            <a className={'a-mail'} href={linkObject.urlMultiLanguage._i18nCL.url}>
              {linkIcon}
              {children}
            </a>
          )
        }
        if (linkObject.urlMultiLanguage._i18nCL.url.startsWith('disableGoogleAnalytics')) {
          return (
            <a href="#!" onClick={setDisableGoogleAnalytics}>
              {children}
            </a>
          )
        }
        return (
          <a
            className={'a-external'}
            href={linkObject.urlMultiLanguage._i18nCL.url}
            target="_blank"
          >
            {linkIcon}
            {children}
          </a>
        )
      } else if (linkObject.hasOwnProperty('overlay') && pathname) {
        return (
          <NavLink
            className={'a-overlay'}
            to={{
              pathname: pathname,
              query: {
                overlay: linkObject.overlay,
              },
            }}
          >
            {linkIcon}
            {children}
          </NavLink>
        )
      } else if (linkObject.hasOwnProperty('navigationNode')) {
        const currentLanguage = getCurrentLanguageOrFallBackByPath(pathname)
        const navigationNode = linkObject.navigationNode

        if (navigationNode.hasOwnProperty('node') && navigationNode.node && currentLanguage) {
          // *************************************************************************************************************
          // internal link with anchor support
          const i18n = navigationNode.node.i18n.hasOwnProperty(currentLanguage)
            ? navigationNode.node.i18n[currentLanguage]
            : null
          if (i18n) {
            if (navigationNode.anchor && navigationNode.anchor.blockTitle) {
              const blockTitle = navigationNode.anchor.blockTitle
              if (blockTitle.i18n && blockTitle.i18n.hasOwnProperty(currentLanguage)) {
                const anchor = blockTitle.i18n[currentLanguage].anchor
                return (
                  <NavLink
                    className={'a-internal'}
                    to={{
                      pathname: i18n.relativeUrl,
                      hash: '#' + anchor.toLowerCase(),
                    }}
                  >
                    {linkIcon}
                    {children}
                  </NavLink>
                )
              }
            }
            return (
              <NavLink className={'a-internal'} to={i18n.relativeUrl}>
                {linkIcon}
                {children}
              </NavLink>
            )
          }
        } else if (navigationNode.hasOwnProperty('i18n') && navigationNode.i18n) {
          // *************************************************************************************************************
          // legacy anchor support
          const i18n = navigationNode.i18n.hasOwnProperty(currentLanguage)
            ? navigationNode.i18n[currentLanguage]
            : null
          return (
            <NavLink className={'a-internal'} to={i18n.relativeUrl}>
              {linkIcon}
              {children}
            </NavLink>
          )
        }
      } else if (
        linkObject.hasOwnProperty('document') &&
        typeof this.props.linkObject.document === 'object'
      ) {
        let mediaUrl = linkObject.document.cloudinaryMedia.url
        if (linkObject.document._i18nCL && linkObject.document._i18nCL.cloudinaryMedia) {
          // use i18 media if present
          mediaUrl = linkObject.document._i18nCL.cloudinaryMedia.url
        }
        return (
          <a className={'a-document'} href={mediaUrl} target="_blank" rel="noopener">
            {linkIcon}
            {children}
          </a>
        )
      }
    } catch (e) {
      console.warn(e)
    }

    return children
  }
}
