import * as React from 'react'
import ModAnchorTag from '../../components/common/ModAnchorTag'
import ModImgTag from '../../components/common/ModImgTag'

export interface ModBlockTeaserSpotProps {
  content: {
    list: any
  }
  pathname: string
}

export default class ModBlockTeaserSpot extends React.Component<ModBlockTeaserSpotProps> {
  render() {
    const {content, pathname} = this.props

    const list = content.list.map(function (item, index) {
      let image = null

      if (content.list.length === 1) {
        image = <ModImgTag imgObject={item.image} />
      } else {
        image = <ModImgTag imgObject={item.image} width={298} height={298} />
      }

      return (
        <div key={index} className="block-teaser-spot-item">
          <ModAnchorTag linkObject={item.link} pathname={pathname}>
            {image}
          </ModAnchorTag>
        </div>
      )
    })

    return (
      <div className="block-teaser-spot">
        <div className={'block-teaser-spot-wrapper items-' + content.list.length}>{list}</div>
      </div>
    )
  }
}
