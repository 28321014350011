import * as React from 'react'

export default class Mod404 extends React.Component {
  render() {
    return (
      <div className="layout-article">
        <div className="block-404-title">
          <div>
            <h1>Not found</h1>
            <p className="block-title-subtitle">The requested URL was not found</p>
          </div>
        </div>

        <div className="block-header block-404-header">
          <h1 className="block-header-title block-header-404-title">404</h1>
          <h6 className="block-header-subtitle block-header-404-subtitle">That’s an error</h6>
        </div>
      </div>
    )
  }
}
