import * as React from 'react'
import {createReactComponents} from '../../common/DraftJsToReact'
import {convertFromRaw} from 'draft-js'

interface ModRichTextProps {
  richText: any
  pathname: string
}

export default class ModRichText extends React.Component<ModRichTextProps> {
  constructor(props) {
    super(props)
  }

  render() {
    const {richText, pathname} = this.props

    return createReactComponents(convertFromRaw(richText), pathname)
  }
}
